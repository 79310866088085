import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});
export default new Vuetify({
  theme: {
    themes: {
      light: {
        accent: "#1f282b",
        secondary: "#2e3a3d", //"#2d3739",
        success: "#f2f2f2",
        primary: "#4C893F"
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"
        /*
        primary: "#51702d",
        secondary: "#1f3531",
        accent: "white", //link text
        error: "#1f3531", //item se
        ////secondary: "#394246lected
        info: "#51702d",
        
        //warning: "#00F"
        warning: "#fcfbf1" //marker text
        
        
        */
      }
    }
  }
});
