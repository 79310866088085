const getters = {};
const mutations = {};
const actions = {};

const state = {
  maplayerdata: {
    extraLegendItems: [
      {
        id: "growth",
        name: "Growth Areas ",
        showIn: { "cri-mb": true, "cri-suburb": true, "cri-sa1": true },
        src: "./assets/layers/growth_40.png",
        color: "red"
      },

      {
        id: "openSpace",
        name: "Parkland (ABS)",
        showIn: { "cri-mb": true },
        src: "./assets/layers/openSpace_40.png",
        color: "red"
      }
    ],
    layers: {
      "cri-suburb": {
        id: "cri-suburb",
        name: "Suburb",
        nameShort: "Suburb",
        value: false,
        min: 0,
        max: 24,
        maxDesirable: 14,
        target: 11,
        color: "#018e42"
      },
      "cri-sa1": {
        id: "cri-sa1",
        name: "Neighborhood (SA1)",
        nameShort: "Neighborhood",
        toolTip: "Neighbourhood (Statistical Area 1)",
        value: false,
        min: 10.3,
        max: 24,
        maxDesirable: 19,
        target: 14.5
      },
      "cri-mb": {
        id: "cri-mb",
        name: "Block (MB)",
        nameShort: "Block",
        toolTip: "Block (Mesh Block)",

        value: true,
        min: 13.3,
        max: 24,
        maxDesirable: 24,
        //maxDesirable: 20,
        target: 15
      }
    },

    thematics: {
      CRI: {
        id: "CRI",
        name: "Climate Resilience Index",
        value: true,
        nameShort: "CRI",
        icon: "fa-solid fa-shield-heart",
        color: "#4C893F", //"#018e42",
        iconFile: "CRI_Icon_Climate-Resilience-Index"
      },
      G_U: {
        id: "G_U",
        name: "Amenities",
        value: false,
        nameShort: "Amenities",
        icon: "mdi-home-city",
        color: "#54428d",
        iconFile: "CRI_Icon_Density-Land-Use"
      },
      G_E: {
        id: "G_E",
        name: "Environment & Biodiversity",
        value: false,
        nameShort: "Environment",
        icon: "mdi-walk",
        //color: "#0168be",
        color: "#046a2b",
        iconFile: "CRI_Icon_Environment-Boidiversity"
      },
      G_T: {
        id: "G_T",
        name: "Transport & Movement",
        value: false,
        nameShort: "Transport",
        icon: "mdi-sprout",
        color: "#dd0e7f",
        iconFile: "CRI_Icon_Transport-Movement"
      },
      G_V: {
        id: "G_V",
        name: "Hazard Resilience",
        value: false,
        nameShort: "HazardRes",
        //icon: "fa-solid fa-house-flood-water",
        icon: "mdi-home-flood",
        color: "#fcb003",
        iconFile: "CRI_Icon_Vulnerability"
      }
    },

    thematicSetAct: {
      CRI_colorblind: [
        { min: 0, max: 40, color: "#F1F58C" },
        { min: 40, max: 45, color: "#CFD979" },
        { min: 45, max: 50, color: "#ACBD67" },
        { min: 50, max: 55, color: "#8AA154" },
        { min: 55, max: 60, color: "#688641" },
        { min: 60, max: 65, color: "#466A2E" },
        { min: 65, max: 70, color: "#234E1C" },
        { min: 70, max: 100, color: "#013209" }
      ],

      CRI_testing: [
        { min: 0, max: 40, color: "purple" },
        { min: 40, max: 45, color: "pink" },
        { min: 45, max: 50, color: "yellow" },
        { min: 50, max: 55, color: "orange" },
        { min: 55, max: 60, color: "black" },
        { min: 60, max: 65, color: "green" },
        { min: 65, max: 70, color: "blue" },
        { min: 70, max: 100, color: "red" }
      ],
      CRI: [
        { min: 0, max: 40, color: "#ff3b4b" },
        { min: 40, max: 45, color: "#fe7434" },
        { min: 45, max: 50, color: "#f7bf16" },
        { min: 50, max: 55, color: "#e7f01e" },
        { min: 55, max: 60, color: "#a1be3e" },
        { min: 60, max: 65, color: "#62943c" },
        { min: 65, max: 70, color: "#317027" },
        { min: 70, max: 100, color: "#013209" }
      ],

      G_U: [
        { min: 0, max: 20, color: "#fcfbfd" },
        { min: 20, max: 30, color: "#edebf4" },
        { min: 30, max: 40, color: "#d4d4e8" },
        { min: 40, max: 50, color: "#b3b3d6" },
        { min: 50, max: 60, color: "#928fc2" },
        { min: 60, max: 70, color: "#7261ab" },
        { min: 70, max: 80, color: "#5a3395" },
        { min: 80, max: 100, color: "#3f007d" }
      ],

      G_E: [
        { min: 0, max: 20, color: "#f7fcf5" },
        { min: 20, max: 30, color: "#e2f4dd" },
        { min: 30, max: 40, color: "#bfe6b9" },
        { min: 40, max: 50, color: "#94d390" },
        { min: 50, max: 60, color: "#60ba6c" },
        { min: 60, max: 70, color: "#329b51" },
        { min: 70, max: 80, color: "#0d7835" },
        { min: 80, max: 100, color: "#046a2b" }
      ],

      G_T: [
        { min: 0, max: 20, color: "#f1eef6" },
        { min: 20, max: 30, color: "#e2cde5" },
        { min: 30, max: 40, color: "#d8aad2" },
        { min: 40, max: 50, color: "#dd7cbb" },
        { min: 50, max: 60, color: "#de50a0" },
        { min: 60, max: 70, color: "#dd267f" },
        { min: 70, max: 80, color: "#bf1061" },
        { min: 80, max: 100, color: "#980043" }
      ],

      G_V_new: [
        { min: 0, max: 60, color: "#644f0a" },
        { min: 60, max: 65, color: "#80670b" },
        { min: 65, max: 70, color: "#9c800c" },
        { min: 70, max: 75, color: "#b8980d" },
        { min: 75, max: 80, color: "#d4b10e" },
        { min: 80, max: 85, color: "#e8ca0d" },
        { min: 85, max: 90, color: "#e7e507" },
        { min: 90, max: 100, color: "#e6ff01" }
      ],

      G_V: [
        { min: 0, max: 60, color: "#fff5eb" },
        { min: 60, max: 65, color: "#fee4ca" },
        { min: 65, max: 70, color: "#fdc997" },
        { min: 70, max: 75, color: "#fda45d" },
        { min: 75, max: 80, color: "#f87f2c" },
        { min: 80, max: 85, color: "#e5590a" },
        { min: 85, max: 90, color: "#b83c02" },
        { min: 90, max: 100, color: "#7f2704" }
      ]
    },

    setMapFromQueryStringPathLastValues: true,

    listOpacity: 0.8,
    legendOpacity: 0.9,
    mapOpacity: 0.9,
    maxValue: 100
  },
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoiY2xpbWF0ZXJlc2lsaWVuY2VpbmRleCIsImEiOiJjbHVya29vaDcwOGkzMmprNTdjdGQ4ZG5zIn0.jtIOV6skoYTXWGcl4ATizw",
      options: {
        style:
          "mapbox://styles/climateresilienceindex/clxtyngie00ql01rnh895bonk",
        center: [144.946457, -37.840935],
        maxZoom: 24,
        minZoom: 7,
        pitch: 40,
        bearing: 9,
        //bearing: 0,
        zoom: 9,
        padding: { top: 300, bottom: 0, left: 150, right: 0 }
      },
      bounds: [
        [141, -40], // Southwest coordinates
        [150, -34] // Northeast coordinates
      ],
      defaultSetup: {
        center: [144.946457, -37.840935],
        zoom: 10,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 0,
        bearing: 0,
        padding: { top: 300, bottom: 0, left: 50, right: 0 }
        //bearing: 0
      },
      flyToBounds: [
        [144.7484, -38.02],
        [145.164729, -37.74390811306237]
      ],
      disableRotationAndBearing: false
    },

    defaultTooltipLabelField: "mv_tooltip",

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "#879c33", //a6caa6
    filterLegendButtonColor: "#51702d", //a6caa6

    appButtonColor: "black",
    dialogActionButtonColor: "black",
    logoTextColor: "#e9e3cd",
    filterButtonColor: "#fae08c",
    mainMenuBarColor: "#51702d",
    menuBarColor: "#879c33"
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
