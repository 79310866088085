<template>
  <div class="mapWrapper">
    <div id="currentZoomDev" v-if="isDevEnviroment">
      {{ currentZoom }}
    </div>
    <mapbox
      :access-token="appSettings.map.accessToken"
      :map-options="{ ...options, ...appSettings.map.options }"
      @map-load="loaded"
      @map-init="initialized"
      :nav-control="{
        show: false,
        position: 'top-left'
      }"
      :scale-control="{
        show: false,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: false,
        position: 'top-left'
      }"
    />
  </div>
</template>

<script>
//cluster ajsdflj https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

import { mapState } from "vuex";
import { eventBus } from "../../main";

import _ from "lodash";

import Mapbox from "mapbox-gl-vue";
//import mapboxgl from "mapbox-gl";

/*


import layerFilterMixin from "./mixins/layer-filter-mixin";
import markerMenuMixin from "./mixins/marker-menu-mixin";
import markerEffectsMixin from "./mixins/marker-effects-mixin";
import tooltipMixin from "./mixins/tooltip-mixin";

*/
import infowindowEventsMixin from "./mixins/infowindow-events-mixin";
import controlsSearchMixin from "./mixins/controls-search-mixin.js";
import filtersControlsMixin from "./mixins/filters-controls-mixin.js";
import legendMixin from "./mixins/legend-mixin.js";
import extraLayersMixin from "./mixins/extra-layers-mixin.js";
import suburbListMixin from "./mixins/suburb-list-mixin.js";
import pathEventsMixin from "./mixins/path-events-mixin.js";

import shareMixin from "./mixins/share-mixin";

export default {
  components: { Mapbox },
  mixins: [
    controlsSearchMixin,
    filtersControlsMixin,
    infowindowEventsMixin,
    legendMixin,
    extraLayersMixin,
    suburbListMixin,

    pathEventsMixin,

    shareMixin
    //layerFilterMixin,
    //markerMenuMixin,
    //markerEffectsMixin,
    //tooltipMixin,
    //mediaEventsMixin
  ],
  name: "TheMap",
  computed: {
    ...mapState({
      isDevEnviroment: state => state.navigation.isDevEnviroment,

      //global:
      appSettings: state => state.global.appSettings,

      layers: state => state.filters.layers
    })
  },

  props: {
    drawer: Boolean
  },
  data: () => ({
    flyToTimeout: null,
    flyToTimeoutBackup: null,
    currentZoom: 0,
    lastMarkerSize: null,
    toolTipAct: null,
    useCluster: false,
    useCircles: true,
    popups: [],
    map: null,
    options: {
      container: "mapContainer",
      projection: "mercator" //equalEarth, globe, naturalEarth, mercator
    }
  }),
  mounted() {},
  created() {
    eventBus.$on("nav_exitFullScreen", () => {
      this.exitFullScreen();
    });

    eventBus.$on("nav_map_reset", () => {
      this.resetMap();
      this.resetUi();
    });
  },
  methods: {
    queryStringLoad(decodeQuerySetup, pitch, center, bearing, zoom) {
      zoom = decodeQuerySetup.zoom;
      center = [decodeQuerySetup.centerLng, decodeQuerySetup.centerLat];

      let infoWindowCenter;
      if (
        decodeQuerySetup.infoWindowTargetLat ||
        decodeQuerySetup.infoWindowTargetLng
      ) {
        center = [
          decodeQuerySetup.infoWindowTargetLng,
          decodeQuerySetup.infoWindowTargetLat
        ];

        infoWindowCenter = {
          latitude: decodeQuerySetup.infoWindowTargetLat,
          lat: decodeQuerySetup.infoWindowTargetLat,
          longitude: decodeQuerySetup.infoWindowTargetLng,
          lng: decodeQuerySetup.infoWindowTargetLng
        };
      }
      this.map.flyTo({ pitch, center, bearing, zoom, xduration: 1000 });

      setTimeout(() => {
        try {
          if (decodeQuerySetup.themeIndex || decodeQuerySetup.layerIndex) {
            this.updateThemeAndLayerFromIndex(
              decodeQuerySetup.themeIndex,
              decodeQuerySetup.layerIndex
            );
          }
        } catch (error) {
          //do nothing;
        }
      }, 100);

      setTimeout(() => {
        try {
          if (decodeQuerySetup.themeIndex || decodeQuerySetup.layerIndex) {
            this.updateThemeAndLayerFromIndex(
              decodeQuerySetup.themeIndex,
              decodeQuerySetup.layerIndex
            );
          }
        } catch (error) {
          //do nothing;
        }

        setTimeout(() => {
          try {
            if (
              decodeQuerySetup.infoWindowTargetLat ||
              decodeQuerySetup.infoWindowTargetLng
            ) {
              this.flyToPoint(infoWindowCenter, true, zoom);
              this.$store.dispatch(
                "navUpdate_infoWindowTarget",
                infoWindowCenter
              );
            }
          } catch (error) {
            //do nothing;
          }
        }, 1000);
      }, 1000);

      setTimeout(() => {
        this.startTrackingEvents();
      }, 2500);
    },
    closeMapControlsFilters() {
      eventBus.$emit("mapFilter_setMobileOpen", false);
    },

    refreshMap_debounced: _.debounce(function() {
      this.refreshMap();
    }, 500),

    scrollToTop() {
      var mapContainer = document.getElementById("mapContainer");
      mapContainer.scrollTop = 0;
      mapContainer.parentElement.scrollTop = 0;
      mapContainer.parentElement.parentElement.scrollTop = 0;
      mapContainer.parentElement.parentElement.parentElement.scrollTop = 0;
      mapContainer.parentElement.parentElement.parentElement.parentElement.scrollTop = 0;
      mapContainer.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop = 0;
      mapContainer.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop = 0;
    },
    refreshMap() {
      setTimeout(() => {
        try {
          this.map.resize();
        } catch (error) {
          //do nothingl
        }
      }, 300);

      setTimeout(() => {
        try {
          this.map.resize();
        } catch (error) {
          //do nothingl
        }
      }, 2300);
    },
    resetUi() {
      eventBus.$emit("mapLegend_resetColourBlindFriendly");
      this.updateThemeAndLayerFromIndex(0, 0);
      eventBus.$emit("mapLegend_setMobileOpen", false);
      eventBus.$emit("mapFilter_setMobileOpen", false);
      eventBus.$emit("tableList_setMobileOpen", false);
      eventBus.$emit("tableList_setDesktopOpen", true);

      setTimeout(() => {
        this.$store.dispatch("navUpdate_clearQueryStringPath");
      }, 3000);
      setTimeout(() => {
        this.$store.dispatch("navUpdate_clearQueryStringPath");
      }, 4000);
    },
    resetMap(firstLoad) {
      let {
        pitch,
        center,
        bearing,
        zoom,
        zoomMob,
        zoomTab,
        padding
      } = this.appSettings.map.defaultSetup;

      if (this.appSettings.map.bounds) {
        this.map.setMaxBounds(this.appSettings.map.bounds);
        this.map.setPadding(padding);
      }

      let decodeQuerySetup = this.getDecodedQueryString();

      if (
        firstLoad &&
        decodeQuerySetup &&
        this?.maplayerdata?.setMapFromQueryStringPathLastValues === true
      ) {
        this.queryStringLoad(decodeQuerySetup, pitch, center, bearing, zoom);
      } else {
        if (this.$vuetify.breakpoint.mobile) {
          if (zoomMob) {
            zoom = zoomMob;
          }
        } else if (this.$vuetify.breakpoint.smAndDown) {
          if (zoomTab) {
            zoom = zoomTab;
          }
        }

        if (firstLoad) {
          //do nothing;
        } else {
          this.clearAllPopups("d");
        }

        if (this.appSettings.map.flyToBounds) {
          let options = { bearing, pitch };
          this.map.fitBounds(this.appSettings.map.flyToBounds, options);
        } else if (this.appSettings.map.defaultSetup) {
          this.map.flyTo({ pitch, center, bearing, zoom });
        }
        setTimeout(() => {
          this.startTrackingEvents();
        }, 2500);
      }
    },
    exitFullScreen() {
      //if fullscreen mode go back to normal
      try {
        let [FullscreenControl] = this.map._controls.filter(
          value => value.constructor.name == "FullscreenControl"
        );
        if (FullscreenControl._fullscreen === true) {
          FullscreenControl._onClickFullscreen();
        }
      } catch (error) {
        //do nothing
      }
    },

    updateMobileFilterView(value) {
      this.$store.dispatch("navUpdate_updateMobileFilterShow", value);
      if (value === true) {
        eventBus.$emit("mapLegend_setMobileOpen", false);
      }
    },
    updateMobileSuburbView(value) {
      this.$store.dispatch("navUpdate_updateMobileSuburbShow", value);
    },

    flyToPoint(item, dontChangeLayer, forceZoom) {
      if (!dontChangeLayer) {
        eventBus.$emit("mapFilter_setLayer", "cri-suburb");
      }
      eventBus.$emit("tableList_closeMobileView");

      let timeMs = 800;
      this.clearAllPopups("c");
      let { latitude, longitude } = item;

      let center = [longitude, latitude];

      let { pitch, bearing, padding } = this.appSettings.map.defaultSetup;

      if (this.$vuetify.breakpoint.smAndDown) {
        padding.left = 0;
      }
      padding.left = 0;
      this.map.setPadding(padding);

      var options = {
        pitch,
        center,
        bearing,
        zoom: forceZoom ? forceZoom : 12.5
      };

      this.map.flyTo({
        ...options,

        duration: timeMs // Animate over 12 seconds
        //essential: true // This animation is considered essential with
      });

      try {
        if (this.flyToTimeout) {
          clearTimeout(this.flyToTimeout);
        }

        if (this.flyToTimeoutBackup) {
          clearTimeout(this.flyToTimeoutBackup);
        }
      } catch (error) {
        //do nothing
      }

      this.flyToTimeout = setTimeout(() => {
        this.map.jumpTo(options);

        let lngLatToPixel = (map, center) => {
          var coords = map.project(center);
          return [coords.x, coords.y];
        };
        let pixel = lngLatToPixel(this.map, center);

        this.map.fire("click", {
          lngLat: center,
          point: pixel,
          originalEvent: {
            // You can add more properties like button, clientX, clientY, etc. if needed
            // For simplicity, only adding the properties needed for a basic click event
            clientX: pixel[0],
            clientY: pixel[1]
          }
        });
      }, timeMs + 100);

      this.flyToTimeoutBackup = setTimeout(() => {
        if (!this.currentPopup) {
          this.map.jumpTo(options);

          let lngLatToPixel = (map, center) => {
            var coords = map.project(center);
            return [coords.x, coords.y];
          };
          let pixel = lngLatToPixel(this.map, center);

          this.map.fire("click", {
            lngLat: center,
            point: pixel,
            originalEvent: {
              // You can add more properties like button, clientX, clientY, etc. if needed
              // For simplicity, only adding the properties needed for a basic click event
              clientX: pixel[0],
              clientY: pixel[1]
            }
          });
        }

        //this.$store.dispatch("navUpdate_infoWindowTarget", item);
      }, timeMs * 2 + 50);
    },

    initialized(/*map*/) {},

    async loaded(map) {
      //window.clearAllPopups = this.clearAllPopups.bind(this);
      map._isVue = true;
      this.map = map;
      /*
      setInterval(() => {
        this.map.resize();

        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 1000);
      }, 4000);
*/
      window.thismap = map;

      if (this.appSettings.map.disableRotationAndBearing) {
        // disable map rotation using right click + drag
        this.map.dragRotate.disable();
        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();
      }

      this.resetMap(true);

      try {
        this.currentZoom = map.getZoom();
        this.currentZoom = Math.round(this.currentZoom * 100) / 100;
      } catch (error) {
        //do nothing;
      }

      map.on("moveend", () => {
        try {
          this.currentZoom = map.getZoom();

          this.currentZoom = Math.round(this.currentZoom * 100) / 100;

          this.$store.dispatch("navUpdate_zoom", this.currentZoom);
        } catch (error) {
          //do nothing;
        }

        try {
          this.$store.dispatch("navUpdate_center", this.map.getCenter());
        } catch (error) {
          //do nothing;
        }
      });

      this.initializeInfoWindowEvents();
      this.addMapZoomFullScreen();
      this.addAddressSearch();
      //this.addFilterControlsSearch();
      this.addMapControlsFilters();
      this.addMapLegend();
      this.addSuburbList();

      this.addShareButtons();

      //this.addExtraLayers();

      //window.addEventListener("resize", this.refreshMap_debounced);
      /*
      setInterval(() => {
        
        this.scrollToTop();
        this.map.resize();
      }, 1000);
*/
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#currentZoomDev {
  height: 28px;
  width: 54px;
  overflow: visible;
  z-index: 2;
  top: 183px;
  left: 10px;
  position: absolute;
  background: white;
  border: 2px solid #ff000052;
  border-radius: 5px;
  text-align: center;
  font-size: 17px;
}
</style>
