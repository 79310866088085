<template>
  <div class="textBodyHeightWrapper" :id="`aboutContentDiv_${id}`">
    <div class="textBodyHeight">
      <v-card-text>
        <center class="">
          <v-btn
            dark
            depressed
            color="primary"
            target="_blank"
            href="https://tract.com.au/climate-resilience-index/"
          >
            Visit the Project home page
          </v-btn>
        </center>
      </v-card-text>

      <v-card flat>
        <v-card-title class="ml-4"
          >How is a suburb, neighbourhood and block defined?</v-card-title
        >
        <v-card-text>
          <ul>
            <li>
              'Suburbs' are defined by the Australian Bureau of Statistics State
              Suburb dataset.
            </li>
            <li>
              'Neighbourhoods' are defined by the ABS Statistical Area 1 (SA1)
              dataset which generally include around 200 to 800 residents.
            </li>
            <li>
              'Blocks' are defined by the ABS Mesh Block (MB) dataset which
              include around 60 to 120 residents.
            </li>
          </ul>
        </v-card-text>

        <v-card-title class="ml-4"
          >What has been analysed within each indicator?</v-card-title
        >
        <v-card-text>
          <ul>
            <li>
              'Amenities' refers to efficient land use and proximate access to a
              diversity of core services, amenities, recreation space, education
              and employment opportunities.
            </li>
            <li>
              'Transport and movement' includes proximate access to functional
              and frequent public transport and a permeable pedestrian network.
            </li>
            <li>
              'Environment and biodiversity' comprises the proportion of canopy
              coverage, blue and green space and ecological connectivity.
            </li>
            <li>
              'Hazard resilience' evaluates the area least prone to flooding,
              coastal inundation, bushfire risk and urban heat effects.
            </li>
          </ul>
        </v-card-text>

        <v-card-title class="ml-4">
          How has the study boundary been determined?
        </v-card-title>
        <v-card-text>
          The study area has been designed to capture the 'contiguous urban
          area' of Melbourne as closely as possible. The boundary has been
          derived from 'Major Urban' and 'Other Urban' Areas as defined in the
          ABS Urban Centres and Localities (UCLs) dataset. Data availability and
          consistency have also been factors in the refinement of the study area
          boundary.
        </v-card-text>

        <v-card-title class="ml-4">
          How is each indicator and sub-indicator weighted?
        </v-card-title>
        <v-card-text>
          <ul>
            <li>
              The Climate Resilience Index is composed of four indicators, which
              have been weighted equally. Amenities, transport and movement,
              environment and biodiversity, and hazard resilience, each have an
              equal bearing on the overall Climate Resilience Index.
            </li>
            <li>
              Each indicator comprises a series of sub-indicators (visible in
              the 'details' section for each area). The information contained
              within each sub-indicator has been weighted differently to reflect
              its importance at each level; areas of frequent train service
              access for example are weighted more favorably that areas with
              less frequent service access.
            </li>
          </ul>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutFAQ",

  props: {
    id: String
  }
};
</script>
