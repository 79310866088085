<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="computerWidth"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }" v-if="false">
        <v-btn color="secondary" dark v-bind="attrs" v-on="on" v-show="false">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="AboutDialogWrapper">
        <v-toolbar dark color="secondary" xcolor="rgb(42 107 52)">
          <v-spacer></v-spacer>
          <v-toolbar-title>About Climate Resilience Index</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs v-model="tab">
              <v-tabs-slider color="rgb(1, 142, 66)"></v-tabs-slider>

              <v-tab v-for="tab in tabSections" :key="tab">
                {{ tab }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tab in tabSections" :key="tab">
            <About :id="tab"></About>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import About from "./content/About.vue";
import { eventBus } from "./../../../main";

export default {
  name: "HelpModal",

  components: { About },

  data() {
    return {
      computerWidth: "600px",
      dialog: false,

      tab: 0,
      tabSections: ["Background", "FAQ", "Sources", "Team"]
    };
  },

  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
      this.tab = 0;

      window.mvAnalyticEvent("dialogOpen_about");
    });
  },

  watch: {
    tab: function() {
      this.tabSections.forEach(tab => {
        var myDiv = document.getElementById(`aboutContentDiv_${tab}`);
        myDiv.scrollTop = 0;
      });
    }
  }
};
</script>

<style>
.AboutDialogWrapper .textBodyHeightWrapper {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
@media only screen and (max-width: 959px) {
  .AboutDialogWrapper .textBodyHeightWrapper {
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }
}

.AboutDialogWrapper .v-tab.v-tab--active {
  background: #616161;
}
@media only screen and (max-width: 959px) {
  div.AboutDialogWrapper .v-tab {
    font-size: 0.65rem;
    padding: 0 8px;
  }
}
</style>
