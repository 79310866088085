<template>
  <div
    :color="'success'"
    v-if="ready"
    class="suburbList dataTableView"
    :class="{
      mobileViewSubrubList:
        $vuetify.breakpoint.mdAndDown && mobileShowSuburbList,
      suburbList: true
    }"
  >
    <v-btn
      class="mapElementButton"
      v-show="
        (!$vuetify.breakpoint.mdAndDown && !desktopShowSuburbList) ||
          ($vuetify.breakpoint.mdAndDown && !mobileShowSuburbList)
      "
      tile
      color="secondary"
      @click="
        $vuetify.breakpoint.mdAndDown
          ? (mobileShowSuburbList = !mobileShowSuburbList)
          : (desktopShowSuburbList = !desktopShowSuburbList);
        setAnalystics('suburbList', 'show');
      "
    >
      <v-icon left>
        mdi-format-list-numbered
      </v-icon>
      <font v-if="false">Suburb List</font>
    </v-btn>

    <v-card
      height="100%"
      :color="'secondary'"
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : tableWidth"
      v-show="
        (!$vuetify.breakpoint.mdAndDown && desktopShowSuburbList) ||
          ($vuetify.breakpoint.mdAndDown && mobileShowSuburbList)
      "
    >
      <v-card-title v-if="false"> </v-card-title>
      <v-form class="pl-3 pb-2 pt-0" dark>
        <div class="d-flex flex-row">
          <div class="white--text" v-if="false">
            isIOS:
            {{ isIOS }}
            itemsPerPageAuto {{ itemsPerPageAuto }}, windowHeight:{{
              windowHeight
            }}
            avaliableHight:{{ avaliableHight }} windowHeightRaw:{{
              windowHeightRaw
            }}
          </div>
          <v-text-field
            dark
            color="primary"
            class="pr-1 ma-0  pt-1"
            v-model="search"
            append-icon="mdi-filter"
            xappend-icon="fa-solid fa-filter"
            label="Suburb Filter"
            single-line
            hide-details
          ></v-text-field>

          <v-btn
            large
            v-show="$vuetify.breakpoint.mdAndDown && mobileShowSuburbList"
            @click="
              mobileShowSuburbList = false;
              setAnalystics('suburbList', 'hide');
            "
            class="ma-1 closeButton"
            icon
            color="white"
            title="Close Suburb List"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn
            v-show="!$vuetify.breakpoint.mdAndDown && desktopShowSuburbList"
            @click="
              desktopShowSuburbList = false;
              setAnalystics('suburbList', 'hide');
            "
            class="ma-0 ml-3 closeButton"
            icon
            color="white"
            title="Close Suburb List"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-form>

      <v-data-table
        class="pt-2 dataTableWrapper"
        width="100%"
        height="100%"
        style="border-radius: 0!important;"
        :headers="headers"
        :items="suburbList"
        :search="search"
        :mobile-breakpoint="10"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, ...itemPerPageOption]
        }"
        dense
      >
        <template v-slot:item.latitude="{ item }">
          <div class="pa-0 ma-0 ml-2">
            <v-btn
              style="    position: relative;top: -3px;"
              color="transparent"
              class="ma-0 pa-0 flyToButton"
              x-small
              rounded
              depressed
              @click="
                functions.flyToPoint(item);
                setAnalystics('itemClickedOn');
              "
            >
              <v-icon color="accent" class="ma-0 pa-0" :right="true"
                >mdi-map-marker-outline</v-icon
              >
            </v-btn>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <a
            @click="
              functions.flyToPoint(item);
              setAnalystics('itemClickedOn');
            "
            style="color:rgba(0, 0, 0, 0.87)"
          >
            <div class="pa-0 pt-1" style="white-space: nowrap; width:120px;">
              <span
                :title="item.name"
                style="display: inline-block; overflow: hidden; text-overflow: ellipsis; width:100%;"
              >
                {{ item.name }}
              </span>
            </div>
          </a>
        </template>
        <template v-slot:item.CRI="{ item }">
          <suburbListBar
            :item="item"
            :fieldId="currentThematicsId"
            :fieldName="currentThematicsName"
            :functions="functions"
            :currentThematics="currentThematics"
            :maplayerdata="maplayerdata"
          />
        </template>

        <template v-slot:item.G_U="{ item }">
          <suburbListBar
            :item="item"
            :fieldId="currentThematicsId"
            :fieldName="currentThematicsName"
            :functions="functions"
            :currentThematics="currentThematics"
            :maplayerdata="maplayerdata"
          />
        </template>

        <template v-slot:item.G_E="{ item }">
          <suburbListBar
            :item="item"
            :fieldId="currentThematicsId"
            :fieldName="currentThematicsName"
            :functions="functions"
            :currentThematics="currentThematics"
            :maplayerdata="maplayerdata"
          />
        </template>

        <template v-slot:item.G_T="{ item }">
          <suburbListBar
            :item="item"
            :fieldId="currentThematicsId"
            :fieldName="currentThematicsName"
            :functions="functions"
            :currentThematics="currentThematics"
            :maplayerdata="maplayerdata"
          />
        </template>

        <template v-slot:item.G_V="{ item }">
          <suburbListBar
            :item="item"
            :fieldId="currentThematicsId"
            :fieldName="currentThematicsName"
            :functions="functions"
            :currentThematics="currentThematics"
            :maplayerdata="maplayerdata"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import { eventBus } from "../../../main";

import suburbListBar from "./suburbListBar.vue";

export default Vue.extend({
  name: "SuburbList",
  components: { suburbListBar },
  data: () => ({
    sortBy: "CRI",
    sortDesc: true,
    windowHeight: 700,
    windowHeightRaw: 700,
    avaliableHight: 700,
    windowMenuHeight: 280,
    windowIosPadding: 130,
    windowMenuHeightFullScreen: 200,
    rowHeight: 32,
    tableWidth: "360px",
    //nameWidth:"120px",
    ready: false,
    currentThematics: {},
    search: "",
    itemsPerPageAuto: 10,
    desktopShowSuburbList: true,
    mobileShowSuburbList: false,
    fullScreenMode: false,

    itemsPerPage: -1,
    itemPerPageOption: [5, 10, 25, -1]
  }),

  props: {
    suburbList: Array,
    functions: Object,
    legendData: Object,
    map: Object,
    appSettings: Object,
    maplayerdata: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    },
    layersObjects() {
      return this.maplayerdata.layers;
    },
    layersArray() {
      return Object.values(this.maplayerdata.layers);
    },
    thematicsObject() {
      return Object.values(this.maplayerdata.thematics);
    },
    currentThematicsName() {
      return this?.currentThematics?.fieldDetails?.name;
    },

    currentThematicsId() {
      return this?.currentThematics?.fieldDetails?.id;
    },
    headers() {
      let header = [
        {
          text: " ",
          align: "end",
          filterable: false,
          sortable: false,
          value: "latitude",
          width: "25px"
        },

        {
          text: "Suburb",
          align: "start",
          filterable: true,
          sortable: true,
          value: "name",
          width: "120px"
        }
        //{ text: "CRI", value: "CRI", filterable: true }
      ];

      if (this?.currentThematicsId !== "CRI") {
        header.push({
          text: "CRI",
          value: "CRI_extra",
          filterable: false,
          width: "30px"
        });
      }

      if (this?.currentThematicsName && this?.currentThematicsId) {
        header.push({
          text: this.currentThematicsName,
          value: this.currentThematicsId,
          filterable: false,
          sortable: true,
          width: this?.currentThematicsId !== "CRI" ? "150px" : "200px"
        });
      } else {
        header.push({
          text: "CRI",
          value: "CRI",
          filterable: false,
          width: "30px"
        });
      }

      return header;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;
    this.ready = true;
    eventBus.$on("mapLegend_updateLegendData", this.updateValue);
    eventBus.$on("tableList_closeMobileView", () => {
      this.mobileShowSuburbList = false;
    });

    eventBus.$on("tableList_setMobileOpen", value => {
      this.mobileShowSuburbList = value ? true : false;
    });

    eventBus.$on("tableList_setDesktopOpen", value => {
      this.desktopShowSuburbList = value ? true : false;
    });
    this.getWindowHeight();
    window.addEventListener("resize", this.getWindowHeight);

    setInterval(() => {
      let fullScreenMode = document.fullscreenElement ? true : false;

      if (fullScreenMode !== this.fullScreenMode) {
        this.fullScreenMode = fullScreenMode;
        this.getWindowHeight();
      }
    }, 400);
  },

  beforeDestroy() {
    // Remove the event listener when the component is destroyed to prevent memory leaks
    window.removeEventListener("resize", this.getWindowHeight);
  },
  methods: {
    setAnalystics(thing, value) {
      window.mvAnalyticEvent("suburbList", thing, value);
    },

    updateValue(value) {
      this.currentThematics = value;
    },

    getWindowHeight() {
      // Update the windowHeight data property with the current window height

      this.windowHeight = window.innerHeight;
      this.windowHeightRaw = window.innerHeight;

      let avaliableHight =
        this.windowHeight -
        (!this.isIOS && this.fullScreenMode == true
          ? this.windowMenuHeightFullScreen
          : this.windowMenuHeight);

      if (this.isIOS) {
        avaliableHight = avaliableHight - this.windowIosPadding;
      }

      //avaliableHight = this.windowHeight - this.windowMenuHeight;

      if (avaliableHight > 10) {
        let rows = Math.ceil(avaliableHight / this.rowHeight);
        this.itemsPerPageAuto = rows;
      } else {
        this.itemsPerPageAuto = 1;
      }

      this.avaliableHight = avaliableHight;
    }
  },
  watch: {
    currentThematicsId: function(value) {
      this.sortBy = value;
      this.sortDesc = true;
    },
    mobileShowSuburbList: function(value) {
      this.functions.updateMobileSuburbView(value);
    }
  }
});
</script>

<style>
.suburbList .v-data-footer__select,
.suburbList .v-data-footer {
  visibility: hidden;
  /* display: none; */
  width: 3px;
  display: none;
}

.mapboxgl-ctrl-group .suburbList button {
  width: auto;
}

.suburbList.mobileViewSubrubList {
  margin-top: -2px !important;

  width: 100vw;
  max-width: 100vw !important;
}

.suburbList
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td.text-start,
.suburbList
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td.text-end {
  padding: 0 !important;
  padding: 0 !important;
}

.mapboxgl-ctrl-group .suburbList button.v-btn.flyToButton {
  height: 20px;
  min-width: auto;
  width: 25px;
}
.mapboxgl-ctrl-group button.closeButton {
  border: none !important;
}

.dataTableWrapper .v-data-table__wrapper {
  max-height: calc(100vh - 180px);

  min-height: calc(100vh - 180px);

  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 300px;
}

.isTabletOrMobile .dataTableWrapper .v-data-table__wrapper {
  max-height: calc(100vh - 220px);

  min-height: calc(100vh - 220px);
  padding-bottom: 300px;
}

.dataTableWrapper .v-data-table__wrapper table {
  text-align: left;
  position: relative;
}

.dataTableWrapper .v-data-table__wrapper th {
  background: white !important;
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: 90px;
}

.dataTableView .dataTableWrapper {
  text-align: left;
  position: relative;
}

.dataTableView .dataTableWrapper .v-data-footer {
  background: white !important;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.dataTableWrapper th {
  /* Set a fixed width for the th element */
  xxwidth: 150px; /* Adjust the width as needed */
}

.dataTableWrapper th span {
  word-wrap: break-word; /* Break long words if needed */
  white-space: normal; /* Allow wrapping */
}
</style>
