var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{
    mobileShowFilters: _vm.mobileShowFilters,
    mobileShowSuburbList: _vm.mobileShowSuburbList,
    mobileHideFilters: !_vm.mobileShowFilters,
    mobileHideSuburbList: !_vm.mobileShowSuburbList,

    //these are used so that small computers are classed as tablets;
    isComputer: !_vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallPcOrTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,

    isTablet: _vm.$vuetify.breakpoint.mdOnly,

    isfullScreenMode: _vm.fullScreenMode,

    isMobile: _vm.$vuetify.breakpoint.smAndDown,
    'my-app': true,
    isApple: _vm.isIOS,
    notApple: !_vm.isIOS
  }},[(_vm.ready)?_c('Layout'):_vm._e(),(true)?_c('WelcomeModal',{staticClass:"mr-8",attrs:{"isReady":_vm.ready,"showButton":false}}):_vm._e(),_c('AcknowledgementModal'),_c('ShareDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }