const valueKey = [
  ["RANK_IN_SUBURB", "OVERALL_RANK"],
  [
    "Core Services",
    " Recreation",
    "Amenities",
    "Education Facilities and Employment"
  ],
  ["Public Transport", " Walkability"],
  [
    "Blue & Green Space",
    "Tree Canopy",
    "Protected Land",
    "Ecological Connectivity"
  ],
  [
    "Flooding & Inundation Resilience",
    "Bushfire Resilience",
    "Urban Heat Resilience"
  ]
];

const indexHeadingIds = ["rank", "G_U", "G_T", "G_E", "G_V"];

const _decode = (properties, maplayersubIndicatorsData) => {
  let subIndicatorsOutput = [];
  let ranks = {};

  try {
    let { thematics } = maplayersubIndicatorsData;
    let { D: subIndicatorsData } = properties;

    subIndicatorsData = subIndicatorsData.split(";");

    subIndicatorsData.forEach((row, rowIndex) => {
      let valuesArray = row.split(",");
      let headingId = indexHeadingIds[rowIndex];
      if (headingId == "rank") {
        try {
          valuesArray.forEach((value, valueIndex) => {
            if (!value) {
              value = false;
            }

            let headingIndex = valueKey[rowIndex][valueIndex];

            ranks[headingIndex] = value;

            try {
              let [a, b] = value.split("/");
              a = parseFloat(a);
              b = parseFloat(b);
              let percentage = (a / b) * 100;

              percentage = Math.round(percentage * 10) / 10;

              ranks[headingIndex + "_percentage"] = `${percentage}`;
            } catch (error) {
              //do nothing;
              //console.log({ error });
            }
          });
        } catch (error) {
          //do nothing;
        }
      } else if (
        headingId &&
        thematics[headingId] &&
        thematics[headingId].name
      ) {
        try {
          let { name } = thematics[headingId];

          let sectionValue = properties[headingId];

          if (sectionValue) {
            try {
              sectionValue = Math.round(parseFloat(sectionValue) * 1) / 1;
              sectionValue = `(${sectionValue})`;
            } catch (error) {
              sectionValue = "";
            }
          } else {
            sectionValue = "";
          }

          let items = {};
          valuesArray.forEach((value, valueIndex) => {
            try {
              value = Math.round(parseFloat(value));
            } catch (error) {
              //do nothing;
            }

            try {
              value = `${value}`;
            } catch (error) {
              //do nothing;
            }
            items[valueKey[rowIndex][valueIndex]] = value;
          });

          subIndicatorsOutput.push({
            heading: name,
            sectionValue,
            headingId,
            items
          });
        } catch (error) {
          //do nothing;
          //console.log({ error });
        }
      }
    });

    return { subIndicatorsOutput, ranks };
  } catch (error) {
    //do nothing;
    return null;
  }
};

module.exports.decode = _decode;
