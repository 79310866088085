<template>
  <div class="textBodyHeightWrapper" :id="`aboutContentDiv_${id}`">
    <div class="textBodyHeight">
      <v-card flat>
        <v-row align="left" no-gutters class="pt-4">
          <v-col cols="12" md="6" class="mt-1  px-4">
            <a href="https://tract.com.au" target="_blank">
              <div style="height:50px">
                <img
                  class="mt-1 "
                  style="max-height: 40px;"
                  src="/assets/logos/Tract.png"
                />
              </div>
              <font v-show="false">https://tract.com.au</font>
            </a>
          </v-col>

          <v-col cols="12" md="6" class="mt-1 px-4">
            <a href="https://www.onemap.com.au/" target="_blank">
              <div style="height:43px">
                <img
                  class="onemap "
                  style="max-height: 43px;"
                  src="/assets/logos/onemap-logo-hiLo.png"
                />
              </div>

              <font v-show="false">https://www.onemap.com.au/</font>
            </a>
          </v-col>
        </v-row>

        <v-card-title>Tract Consultants</v-card-title>

        <v-card-text class="aboutText">
          <p>
            Tract is a national planning and design practice specialising in
            town planning, urban design, landscape architecture and associated
            digital media.
          </p>
          <p>
            As planners and designers our charter is to deliver sustainable and
            memorable solutions, shaping places for living, leisure and work,
            and the infrastructure that supports and connects these places.
          </p>

          <a href="https://tract.com.au" target="_blank">
            https://tract.com.au
          </a>
        </v-card-text>

        <v-card-title>OneMap</v-card-title>
        <v-card-text class="aboutText">
          <p>
            OneMap is a Property Intelligence and Site Finding Solutions
            Provider.
          </p>
          <p>
            OneMap's team of GIS Experts and Software Developers is dedicated to
            reshaping the landscape of understanding in property intelligence.
          </p>
          <p>
            Through the lens of cutting-edge technology and spatial
            intelligence, we illuminate the intricate tapestry of each location,
            saving our clients time, and empowering decision-makers to grasp the
            full spectrum of possibilities.
          </p>
          <p>
            OneMap provides clarity amidst complexity, guiding stakeholders
            towards informed, impactful decisions.
          </p>

          <a href="https://www.onemap.com.au/" target="_blank">
            https://www.onemap.com.au/
          </a>
        </v-card-text>

        <center>
          <v-card-text>
            <v-btn
              class="px-10"
              height="48px"
              color="primary"
              href="https://www.onemap.com.au/cri-get-in-touch/"
              target="_blank"
              style="line-height: 18px;"
            >
              Get in touch<br />
              Contact form
            </v-btn>
          </v-card-text>
        </center>
        <br /><br /><br />
      </v-card>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../../../main";

export default {
  name: "AboutBackground",

  props: {
    id: String
  },

  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    nav_about() {
      eventBus.$emit("loadAboutDialog");
    },
    nav_video() {
      eventBus.$emit("loadVideo");
    }
  }
};
</script>
