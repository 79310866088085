<template>
  <div class="textBodyHeightWrapper" :id="`aboutContentDiv_${id}`">
    <div class="textBodyHeight">
      <v-card flat>
        <v-card-text>
          The Climate Resilience Index is a tool developed by Tract and OneMap
          to assess the capacity of Melbourne’s neighbourhoods to effectively
          address climate change, through evaluating the determinants of urban
          environments that impact sustainable living.
        </v-card-text>
        <v-card-text>
          The four key determinants include amenities, transport and movement,
          environment and biodiversity and hazard resilience. Each indicator is
          expanded into a series of assessable sub-indicators to generate a
          Climate Resilience Index score and corresponding score for each group.
        </v-card-text>
        <v-card-text>
          The map layers collate the analysis to visualise the total Index score
          and associated themes at suburb, neighbourhood and block level. The
          indicators can be isolated to provide insight into how each
          contributes to the overarching climate resilience of neighbourhoods.
        </v-card-text>
        <v-card-text>
          <center>
            <v-btn
              class="ytVideo"
              depressed
              color="primary"
              @click="
                nav_video();
                dialog = false;
              "
            >
              <v-icon left>mdi-youtube</v-icon>
              Introduction
            </v-btn>
          </center>
        </v-card-text>
        <v-card-text>
          <center class="">
            <v-btn
              dark
              depressed
              color="primary"
              target="_blank"
              href="https://tract.com.au/climate-resilience-index/"
            >
              Visit the Project home page
            </v-btn>
          </center>
        </v-card-text>
        <br /><br /><br />
      </v-card>
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../../../../main";

export default {
  name: "AboutBackground",

  props: {
    id: String
  },

  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    nav_about() {
      eventBus.$emit("loadAboutDialog");
    },
    nav_video() {
      eventBus.$emit("loadVideo");
    }
  }
};
</script>
