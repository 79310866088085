<template>
  <div v-if="ready">
    <v-card dark>
      <v-btn
        class="mapElementButton mapboxControlButton"
        dark
        color="secondary"
        @click="open()"
      >
        <v-icon dark left default color="black">
          mdi-share-variant
        </v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import { eventBus } from "../../../main";

export default Vue.extend({
  name: "ShareButton",
  data: () => ({ ready: false }),

  props: {
    appSettings: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;
    this.ready = true;
  },
  methods: {
    open() {
      eventBus.$emit("openShareDialog");
    }
  }
});
</script>

<style scoped>
.mapboxControlButton {
  height: 29px !important;
}

.mapboxControlButton .v-btn__content .v-icon.v-icon--left {
  font-size: 24px;
}
</style>
