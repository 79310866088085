<template>
  <div>
    <div v-show="true" class="pl-1">
      <v-divider class="my-3 mx-2"></v-divider>

      <v-row no-gutters class="popupDetailsItemsWrapper">
        <template v-for="layout in layouts">
          <v-col cols="6" :key="`layout_${layout}`">
            <template v-for="(set, index) in decodedValues.subIndicatorsOutput">
              <div
                :key="`set_${index}`"
                class="pb-3"
                v-if="index % 2 === layout"
              >
                <div class="subHeadingItem">
                  <strong class="subHeadingItem pr-2">
                    {{ set.heading }} {{ set.sectionValue }}
                  </strong>
                </div>
                <div
                  v-for="(item, itemKey) in set.items"
                  :key="`set_${index}_set_item_${itemKey}`"
                >
                  {{ itemKey }}: <strong>{{ item }}</strong>
                </div>
              </div>
            </template>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupDetials",
  components: {},

  data: () => ({
    layouts: [0, 1]
  }),

  props: {
    properties: Object,
    maplayerdata: Object,
    decodedValues: Object
  },

  computed: {}
};
</script>

<style>
.scoreHeading {
  font-size: 12px;
}

.popupDetailsItemsWrapper {
  font-size: 11px;
}

.popupDetailsItemsWrapper .subHeadingItem {
  font-size: 12px;
  font-weight: 900;
  max-width: 90%;
}
</style>
