<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :width="computerWidth"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }" v-if="false">
        <v-btn color="secondary" v-bind="attrs" v-on="on" v-show="false">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="AboutDialogWrapper">
        <v-toolbar dark color="rgb(20, 96, 150)" elevation="1">
          <v-toolbar color="#acd265" elevation="1" v-if="false"></v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>Get in Touch</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="contactTextBody">
          <v-card-text>
            Thank you for your interest in the Climate Resilience Index.
          </v-card-text>
          <v-card-text>
            Please click on the button below to be forwarded to a contact form
            if you would like to get in touch.
          </v-card-text>
          <center>
            <v-card-text>
              <v-btn
                class="px-10"
                height="48px"
                color="primary"
                href="https://www.onemap.com.au/cri-get-in-touch/"
                target="_blank"
                style="line-height: 18px;"
              >
                Get in touch<br />
                Contact form
              </v-btn>
            </v-card-text>
          </center>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "./../../main";

export default {
  name: "HelpModal",

  components: {},

  data() {
    return {
      computerWidth: "500px",
      dialog: false
    };
  },

  created() {
    eventBus.$on("loadContactUsDialog", () => {
      this.dialog = true;

      window.mvAnalyticEvent("dialogOpen_contact");
    });
  }
};
</script>

<style scoped>
a {
  text-decoration: underline !important;
}
.contactTextBody {
  height: auto;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

ul.contact {
  padding-left: 0;
}

ul.contact li {
  padding-bottom: 10px;
}
</style>
