<template>
  <div v-if="ready">
    <v-btn
      class="mapElementButton"
      v-show="$vuetify.breakpoint.mdAndDown && !mobileShowLegend"
      tile
      color="secondary"
      @click="
        mobileShowLegend = !mobileShowLegend;
        setAnalystics('mobileLegend', 'show');
      "
    >
      <v-icon left v-if="false">
        mdi-map-legend
      </v-icon>

      <v-icon left>
        mdi-format-list-bulleted-square
      </v-icon>
      <font v-if="false">Suburb List</font>
    </v-btn>

    <div
      v-show="
        ($vuetify.breakpoint.mdAndDown && mobileShowLegend) ||
          !$vuetify.breakpoint.mdAndDown
      "
      style="min-width:110px"
      class="legendBlox pa-2"
      v-if="
        currentThematics &&
          currentThematics.fieldDetails &&
          currentThematics.fieldDetails.name
      "
    >
      <div class="d-flex justify-space-between">
        <h3 class="legendHeading font-weight-bold" style="">
          {{ currentThematics.fieldDetails.name }}
        </h3>

        <v-btn
          x-small
          v-show="$vuetify.breakpoint.mdAndDown && mobileShowLegend"
          @click="
            mobileShowLegend = false;
            setAnalystics('mobileLegend', 'hide');
          "
          class="ma-1 mt-0"
          icon
          color="primary"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div
        v-for="(item, i) in currentThematics.legendArray.slice().reverse()"
        :key="`legendItem_${i}`"
      >
        <div class="d-flex align-start">
          <div
            class="legendItemColorBox mr-2"
            :style="{
              opacity: maplayerdata.legendOpacity,
              overflow: 'hidden',
              height: '20px',
              width: '20px',
              'background-color': item.color
            }"
          ></div>
          <div class="legendItem">{{ item.min }} - {{ item.max }}</div>
        </div>
      </div>
      <div class="mt-2">
        <div
          v-for="(item, i) in extraLegendItems"
          :key="`legendExtraItem_${i}`"
          class="mt-1"
          style="min-height:22px"
          v-show="item.showIn[currentLayerId]"
        >
          <div class="d-flex align-start" v-if="item.showIn[currentLayerId]">
            <img
              class=" mr-2"
              height="20px"
              width="20px"
              v-if="item.src"
              :src="item.src"
            />

            <div class="legendItem">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <v-checkbox
        dense
        class="colourBlindFriendlyButton ma-0 pa-0 mt-2"
        color="rgb(20, 96, 150)"
        v-model="colourBlindFriendly"
        :label="`Colour Blind Friendly Theme`"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import { eventBus } from "../../../main";

export default Vue.extend({
  components: {},
  data: () => ({
    colourBlindFriendly: false,
    ready: false,
    mobileShowLegend: false,
    currentThematics: {},
    currentLayerId: ""
  }),

  props: {
    functions: Object,
    legendData: Object,
    map: Object,
    appSettings: Object,
    maplayerdata: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    extraLegendItems() {
      return this.maplayerdata.extraLegendItems;
    },
    layersObjects() {
      return this.maplayerdata.layers;
    },
    layersArray() {
      return Object.values(this.maplayerdata.layers);
    },
    thematicsObject() {
      return Object.values(this.maplayerdata.thematics);
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;

    this.ready = true;

    eventBus.$on("mapLegend_resetColourBlindFriendly", () => {
      this.colourBlindFriendly = false;
    });
    eventBus.$on("mapLegend_updateLegendData", this.updateValue);

    eventBus.$on("mapLegend_updateLayerId", this.updateCurrentLayerId);

    eventBus.$on("mapLegend_setMobileOpen", value => {
      this.mobileShowLegend = value ? true : false;
    });
  },

  methods: {
    setAnalystics(thing, value) {
      window.mvAnalyticEvent("legend", thing, value);
    },

    updateValue(value) {
      this.currentThematics = value;
    },

    updateCurrentLayerId(value) {
      this.currentLayerId = value;
    }
  },

  watch: {
    colourBlindFriendly: function(value) {
      eventBus.$emit("mapFilter_setColourBlindFriendly", value);
    }
  }
});
</script>

<style>
.legendHeading {
  width: 105px;
  height: 46px;
  font-size: 14px;
}
.legendItem {
  font-size: 13px;
  font-weight: 600;
}

.v-input.colourBlindFriendlyButton,
.v-input.colourBlindFriendlyButton .v-input__control {
  height: 40px !important;
  max-width: 130px;
}

.v-input.colourBlindFriendlyButton label {
  font-size: 13px !important;
  font-weight: 600;
  max-width: 130px;
}
</style>
