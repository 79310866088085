<template>
  <center>
    <div v-if="!contactFunction" class="logoSet">
      <img style="max-height: 25px;" src="/assets/logos/Tract.png" />

      <img
        class="onemap"
        style="max-height: 27px;"
        src="/assets/logos/onemap-logo-hiLo.png"
      />
    </div>
    <div
      v-else-if="$vuetify.breakpoint.mdAndUp && contactFunction"
      class="logoSet"
    >
      <a xclick="contactFunction" href="https://tract.com.au/" target="_blank">
        <img style="max-height: 25px;" src="/assets/logos/Tract.png" />
      </a>

      <a xclick="contactFunction" href="https://onemap.com.au/" target="_blank">
        <img
          class="onemap"
          style="max-height: 27px;"
          src="/assets/logos/onemap-logo-hiLo.png"
        />
      </a>
    </div>
  </center>
</template>

<script>
export default {
  name: "NormalLogos",

  props: {
    contactFunction: Function
  }
};
</script>
<style>
.logoSet img {
  margin: 0 10px;
}

.isTabletx .logoSet {
  width: 100px;
}
.isTabletx .logoSet img {
  margin: 0;
  margin-top: 4px;
  max-height: 25px !important;
}
.isTabletx .logoSet img.onemap {
  margin: 0;

  margin-top: 0;
  max-height: 17px !important;
}

.menuBarItem {
  width: 320px;
}
@media only screen and (max-width: 59px) {
  .menuBarItem {
    width: auto;
  }
}
</style>
