import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
//import markers from "./modules/markers.js";
import navigation from "./modules/navigation.js";
//import media from "./modules/media.js";
import global from "./modules/global.js";
import filters from "./modules/filters.js";
//import translator from "./modules/translator.js";

import mapsetupdate from "./modules/mapsetupdate.js";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global,
    filters,
    navigation,
    mapsetupdate
  }
});
