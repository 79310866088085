<template>
  <v-card elevation="6" x_shaped v-if="ready" class="pb-1" :color="'success'">
    <div style="height:0;width:100%;overflow: visible; text-align: right;">
      <v-btn @click="clearAllPopups" class="ma-1" icon color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="titleBox  white--text">
      <v-card-title
        v-if="properties.id"
        style="background-color: #2d3739;background-color: #2E3A3D;"
      >
        {{ properties.id }}
      </v-card-title>

      <v-card-title
        v-if="properties.Name"
        style="background-color: #2d3739;background-color: #2E3A3D;"
      >
        {{ properties.Name }}
      </v-card-title>

      <v-card-subtitle
        v-if="properties.T"
        style="background-color: #2d3739;background-color: #2E3A3D;"
      >
        {{
          properties.T === "S"
            ? "Suburb"
            : properties.T === "N"
            ? "SA1"
            : properties.T === "B"
            ? "Mesh Block"
            : properties.T
        }}
        <font v-if="properties.Parent">within {{ properties.Parent }}</font>
      </v-card-subtitle>
    </div>

    <v-card-subtitle
      key=""
      style="background-color:white;"
      class="topScoreWrapper ma-0 pa-0 pl-0 pb-2"
    >
      <center>
        <div class="mainPrermHeading pt-2 pb-1">
          Climate Resilience Index: {{ criScoreString }}
        </div>

        <div class="mainRating" :key="`starKeyWrapper_${criObject.id}`">
          <v-rating
            class="mainRatingBackground"
            :key="`starKey_D_${criObject.id}`"
            color="grey"
            dense
            :empty-icon="'mdi-circle-outline'"
            :full-icon="'mdi-circle'"
            :half-icon="'mdi-circle-half'"
            half-increments
            :length="outOfValue"
            readonly
            size="30"
            :value="outOfValue"
          ></v-rating>

          <v-rating
            class="mainRatingAct"
            :key="`starKey_C_${criObject.id}`"
            :color="criObject.color"
            :background-color="'transparent'"
            dense
            :empty-icon="'mdi-circle-outline'"
            :full-icon="'mdi-circle'"
            :half-icon="'mdi-circle-half'"
            half-increments
            :length="outOfValue"
            readonly
            size="30"
            :value="criObject.value"
          ></v-rating>
        </div>
      </center>
    </v-card-subtitle>
    <div class="pa-1">
      <template v-for="item in thematicsSetup">
        <div
          style="width:100%;"
          class="d-flex justify-space-between"
          :key="`starKeyWrapper_${item.id}`"
          v-if="item.index != 0"
        >
          <div class="starKeyTitle pt-0" :key="`starKeyTitle_${item.id}`">
            <font class="peramHeading font-weight-bold">{{ item.name }} :</font>
          </div>

          <div class="subRating">
            <v-rating
              :key="`starKey_B_${item.id}`"
              :color="'grey'"
              :background-color="'grey'"
              dense
              :empty-icon="'mdi-circle-outline'"
              :full-icon="'mdi-circle'"
              :half-icon="'mdi-circle-half'"
              half-increments
              :length="outOfValue"
              readonly
              :size="isIOS ? 12 : 16"
              :value="outOfValue"
            ></v-rating>

            <v-rating
              class="subRatingAct"
              :key="`starKey_A_${item.id}`"
              :color="item.color"
              :background-color="'transparent'"
              dense
              :empty-icon="'mdi-circle-outline'"
              :full-icon="'mdi-circle'"
              :half-icon="'mdi-circle-half'"
              half-increments
              :length="outOfValue"
              readonly
              :size="isIOS ? 12 : 16"
              :value="item.value"
            ></v-rating>
          </div>
          <div class="pt-1" :key="`starKeyValue_${item.id}`" v-show="false">
            <h2>({{ Math.round(item.value * 10) / 10 }} / {{ outOfValue }})</h2>
          </div>
        </div>
      </template>
    </div>
    <PopupRank
      :maplayerdata="maplayerdata"
      :properties="properties"
      :decodedValues="decodedValues"
    />

    <div>
      <v-expand-transition>
        <div v-show="showDetails">
          <PopupDetails
            :maplayerdata="maplayerdata"
            :properties="properties"
            :decodedValues="decodedValues"
          />
        </div>
      </v-expand-transition>
    </div>

    <v-card-actions class="pb-0 mb-0 pt-0 mt-1 mx-0 px-1">
      <v-btn
        small
        depressed
        block
        class="white--text"
        color="primary"
        @click="
          showDetails = !showDetails;
          setAnalystics(showDetails ? 'details_show' : 'details_hide');
        "
      >
        <v-row align="center">
          <v-col>
            <span class="text-left"></span>
          </v-col>

          <v-col>
            <span class="text-center white--text">
              {{ !showDetails ? "show details" : "hide details" }}
            </span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon :right="true">{{
              showDetails ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-col>
        </v-row>
      </v-btn>

      <v-btn
        v-if="false"
        width="100%"
        right
        :color="'primary'"
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { decode } from "./decode";

import Vue from "vue";

import PopupDetails from "./PopupDetails.vue";
import PopupRank from "./PopupRank.vue";

export default Vue.extend({
  components: { PopupDetails, PopupRank },
  data: () => ({
    outOfValue: 5,
    //colors: ["red", "blue", "green", "orange", "yellow"],
    //colors: ["#018e42", "#54428d", "#0168be", "#dd0e7f", "#fcb003"],
    showDetails: false,
    ready: false
  }),

  props: {
    //goToPointByPointName: Function,

    clearAllPopups: Function,
    appSettings: Object,
    maplayerdata: Object,
    properties: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    colors() {
      //let colors = ["#018e42", "#54428d", "#0168be", "#dd0e7f", "#fcb003"];
      let thematics = Object.values(this.maplayerdata.thematics);
      let colors = thematics.map(row => row.color);
      return colors;
    },

    decodedValues() {
      return decode(this.properties, this.maplayerdata);
    },

    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    },

    layersObjects() {
      return this.maplayerdata.layers;
    },
    layersArray() {
      return Object.values(this.maplayerdata.layers);
    },
    thematicsKeys() {
      return Object.keys(this.maplayerdata.thematics);
    },

    maxValue() {
      return this.maplayerdata.maxValue;
    },
    thematicsSetup() {
      let output = [];

      Object.values(this.maplayerdata.thematics).forEach((row, index) => {
        let value = 0.5;
        try {
          value = (this.properties[row.id] / this.maxValue) * this.outOfValue;
        } catch (error) {
          //do nothing;
        }

        output.push({
          index,
          color: this.colors[index],
          id: row.id,
          name: row.name,
          value
        });
      });

      return output;
    },

    criObject() {
      return this.thematicsSetup[0];
    },
    criScore() {
      return (
        Math.round((this.criObject.value / this.outOfValue) * 100 * 100) / 100
      );
    },

    criScoreString() {
      return `${this.criScore} `;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;
    this.ready = true;

    let type = "unknown";
    let value = "na";

    let properties = this.properties;

    try {
      try {
        type =
          properties.T === "S"
            ? "suburb"
            : properties.T === "N"
            ? "sa1"
            : properties.T === "B"
            ? "meshblock"
            : "unknown";
      } catch (error) {
        //do nothing;
      }

      if (properties.Name) {
        value = properties.Name;
      } else if (properties.id) {
        value = properties.id;
      }

      if (value) {
        //do nothingk
      }

      this.setAnalystics("popupOpenType", type);
      this.setAnalystics("popupOpen");
    } catch (error) {
      //do nothing;
    }
  },

  methods: {
    setAnalystics(thing, value) {
      window.mvAnalyticEvent("popup", thing, value);
    }
  }
});
</script>
<style>
.moreInfoButton {
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 5px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  width: 285px;
  max-width: 95vw;
}

.starKeyTitle {
  width: 180px;
}

.diabled.isApple.isMobile .starKeyTitle {
  width: 280px;
}

.diabled.isApple.isMobile .galPopup .mapboxgl-popup-content .v-card {
  width: 400px;

  max-width: 90vw;
}

.titleBox {
}

/*
@media only screen and (max-width: 1263px) {
  .galPopup .mapboxgl-popup-content {
    xzoom: 85%;
  }
}
*/
@media only screen and (max-width: 650px) {
  .notApple .galPopup .mapboxgl-popup-content {
    xmargin-top: 12px;
    zoom: 70%;
  }
}

div.subRating,
div.mainRating {
  position: relative;
}

div.mainRating {
  height: 33px;
}

.subRating {
  text-align: right;

  width: 100px;
  height: 25px;
  margin-left: -7px;
}

.diabled.isApple.isMobile .subRating {
  width: 130px;
}

div.mainRating > div,
div.subRating > div {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mainRating button {
  margin: 0 10px;
}

.mainPrermHeading {
  xpadding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.peramHeading {
  font: 12px / 20px Lato Arial, Helvetica, sans-serif;
}

.xxmainRatingBackground {
}

.diabled.isApple.isMobile .topScoreWrapper {
  min-height: 100px;
}
</style>
