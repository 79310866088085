<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.xsOnly ? '95%' : 500"
    >
      <template v-slot:activator="{ on, attrs }" v-if="showButton">
        <span
          v-bind="attrs"
          v-on="on"
          class=" "
          v-show="showButton"
          @click="scrollToSiteDelay()"
        >
          Welcome
        </span>
      </template>

      <v-card class="dialog px-0">
        <div class="d-flex justify-space-between">
          <div></div>
          <img
            class="mt-3"
            style="width:300px;max-width:70%"
            src="/assets/branding/Stacked/CRI_Logo_Stacked_Colour_2.png"
          />

          <v-btn
            class="right-item"
            depressed
            height="50px"
            color="white"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="px-3">
          <v-row>
            <v-col
              cols="12"
              lg="12"
              id="AboutContentWrapper"
              class="contentWrapper pb-0 WelcomeText"
            >
              <center>
                <v-card-subtitle
                  v-if="true"
                  :style="{
                    background: 'white'
                  }"
                  ><center></center>
                </v-card-subtitle>
              </center>

              <center>
                <p>
                  Resilient neighbourhoods support lower-carbon emissions
                  through access to services and infrastructure, have low
                  vulnerability to climate change impacts, and protect
                  biodiversity.
                </p>
                <p>
                  The Climate Resilience Index is a tool developed by Tract and
                  OneMap to assess the capacity of Melbourne’s neighbourhoods to
                  effectively address climate change, through evaluating the
                  determinants of urban environments that impact sustainable
                  living.
                </p>
              </center>

              <center>
                <v-btn
                  class="mb-2"
                  dark
                  depressed
                  color="primary"
                  target="_blank"
                  href="https://tract.com.au/climate-resilience-index/"
                >
                  Visit the Project home page
                </v-btn>
              </center>

              <center v-if="showButton">
                <v-btn
                  dark
                  width="100%"
                  class="ytVideo"
                  depressed
                  color="rgb(169 26 68)"
                  @click="nav_video()"
                >
                  <v-icon left>mdi-youtube</v-icon>
                  Play Introduction
                </v-btn>
              </center>
            </v-col>
          </v-row>

          <v-card-actions class="px-0 mt-3">
            <v-btn
              class="mr-1 ytVideo"
              depressed
              color="primary"
              @click="nav_video()"
            >
              <v-icon left>mdi-youtube</v-icon>
              <font v-show="$vuetify.breakpoint.smAndUp">Introduction</font>
              <font v-show="!$vuetify.breakpoint.smAndUp">Intro</font>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              class="mr-1"
              color="primary"
              @click="
                nav_about();
                dialog = false;
              "
            >
              About
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "./../../main";
import { mapState } from "vuex";

export default {
  name: "WelcomeModal",
  components: {},
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to TheMap"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },
  created() {
    eventBus.$on("loadWelcomeDialog", () => {
      window.mvAnalyticEvent("dialogOpen_welcome");

      this.dialog = true;
    });
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },
  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    nav_about() {
      eventBus.$emit("loadAboutDialog");
    },
    nav_video() {
      eventBus.$emit("loadVideo");
    }
  },
  mounted() {
    let bootOption = false;

    if (bootOption != true) {
      this.dialog = true;
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper,
  .introVideo {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .isApple .contentWrapper,
  .isApple .introVideo {
    max-height: calc(100vh - 260px);
  }

  .isApple .imageWrapper {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
