<template>
  <div v-if="ready" id="mapControlsWrapper" class="ma-0 pa-0">
    <v-card
      dark
      :color="'secondary'"
      class="white--text "
      v-show="$vuetify.breakpoint.smAndDown && !mobileShowFilters"
    >
      <div id="mapControMobilebutton">
        <v-btn
          title="Show Thematic and Layer Filters "
          dark
          :color="'secondary'"
          class="mapElementButton white--text "
          v-show="$vuetify.breakpoint.smAndDown && !mobileShowFilters"
          tile
          @click="
            mobileShowFilters = !mobileShowFilters;
            setAnalystics('showHideMobileControls', 'show');
          "
        >
          <v-icon left>
            mdi-layers
          </v-icon>

          <div
            style=" height: 0px;
                  width: 0px;
                  overflow: visible;
                  position: relative;
                  left: -31px;
                  top: -17px;
                  zoom: 60%;"
          >
            <v-icon right>
              mdi-filter
            </v-icon>
          </div>
        </v-btn>
      </div>
    </v-card>

    <div class="mapControlsAct">
      <v-card
        dark
        :color="'secondary'"
        class="white--text pb-1"
        v-show="!$vuetify.breakpoint.smAndDown || mobileShowFilters"
      >
        <div
          class="closeBoxMapControls"
          v-show="$vuetify.breakpoint.smAndDown && mobileShowFilters"
        >
          <v-btn
            large
            @click="
              mobileShowFilters = false;
              setAnalystics('showHideMobileControls', 'hide');
            "
            class="ma-0 pa-0"
            icon
            color="black"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div id="mapControlButtons">
          <v-btn-toggle
            mandatory
            class="mapControlButtonsThemeSelector mapControl_toggles d-flex flex-wrap"
            v-model="selectedTheme"
            borderless
            @change="setAnalystics('theme', selectedTheme)"
          >
            <v-btn
              :class="{
                CRI: index == 0,
                mapControl_criThemeButtons: true,
                onePerLine: index == 0,
                twoPerLine: index != 0,
                boarderRight: index == 1 || index == 3
              }"
              :dense="true"
              v-for="(layer, index) in thematicsArraySub"
              :key="`layer_btn_group_item_${layer.id}`"
              :label="layer.name"
              :value="layer.id"
              :title="'Style data by ' + layer.name"
              :color="selectedTheme === layer.id ? layer.color : 'transparent'"
              textx
              :width="
                $vuetify.breakpoint.mdOnly
                  ? '100%'
                  : index == 0
                  ? '100%'
                  : '50%'
              "
            >
              <v-icon v-if="false">
                {{ layer.icon }}
              </v-icon>

              <img
                class="buttonIcon"
                :src="`/assets/branding/Icons/${layer.iconFile}_Light.png`"
                alt="Custom Icon"
              />

              <div class="buttonLabel themeButtonLabel">{{ layer.name }}</div>
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="layerSection mt-2 mb-1 mx-1">
          <center>
            <v-btn-toggle
              :class="{
                mapControl_toggles: true,
                mapControl_criLayerButtons: true,
                'd-flex': $vuetify.breakpoint.mdAndDown || true,
                'flex-wrap': $vuetify.breakpoint.mdAndDown || true,
                'justify-center': $vuetify.breakpoint.mdAndDown || true
              }"
              mandatory
              v-model="selectedLayer"
              @change="
                setAnalystics('layer', selectedLayer);
                functions.updateZoomDueToLayerLayer(selectedLayer);
              "
              @click="functions.updateZoomDueToLayerLayer(selectedLayer)"
            >
              <v-btn
                xstyle="margin-left:2px;margin-bottom:2px"
                class="selectedLayerButtons"
                width="auto"
                :block="$vuetify.breakpoint.mdOnly ? true : false"
                v-for="layer in layersObjects"
                :key="`layer_selector_${layer.id}`"
                :label="layer.nameShort"
                :value="layer.id"
                :title="'Set detail to ' + layer.name + ' scale'"
                :color="selectedLayer === layer.id ? 'white' : 'transparent'"
              >
                <div
                  class="buttonLabel layerButtonLabel"
                  :class="{
                    'black--text': selectedLayer === layer.id
                  }"
                >
                  {{ layer.name }}
                </div>
              </v-btn>
            </v-btn-toggle>
          </center>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../../main";

import Vue from "vue";

export default Vue.extend({
  components: {},
  data: () => ({
    colourBlindFriendly: false,
    fullScreenMode: false,
    mobileShowFilters: false,
    ready: false,
    selectedTheme: "CRI",
    selectedLayer: "cri-suburb"
  }),

  props: {
    functions: Object,
    map: Object,
    appSettings: Object,
    maplayerdata: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    layersObjects() {
      return this.maplayerdata.layers;
    },
    layersArray() {
      return Object.values(this.maplayerdata.layers);
    },
    thematicsArray() {
      return Object.values(this.maplayerdata.thematics);
    },
    thematicsArraySub() {
      let [...subList] = this.thematicsArray;
      return [...subList];
    },

    layersKeys() {
      return Object.keys(this.maplayerdata.layers);
    },
    thematicsKeys() {
      return Object.keys(this.maplayerdata.thematics);
    }
  },
  mounted() {
    eventBus.$on("mapFilter_setColourBlindFriendly", value => {
      this.colourBlindFriendly = value;

      if (this.selectedTheme == "CRI") {
        this.functions.updateThematics(
          this.selectedTheme,
          this.colourBlindFriendly
        );
      }
    });

    this.$vuetify = this.vuetifyCopy;
    this.ready = true;

    this.functions.updateThematics(
      this.selectedTheme,
      this.colourBlindFriendly
    );
    this.functions.updateLayer(this.selectedLayer);

    eventBus.$on("mapFilter_setLayer", this.setLayer);
    eventBus.$on("mapFilter_setThematics", this.setThematics);

    eventBus.$on("mapFilter_setMobileOpen", value => {
      this.mobileShowFilters = value ? true : false;
    });

    setInterval(() => {
      let fullScreenMode = document.fullscreenElement ? true : false;

      if (fullScreenMode !== this.fullScreenMode) {
        this.fullScreenMode = fullScreenMode;
      }
    }, 400);

    this.map.on("moveend", () => {
      try {
        var zoom = this.map.getZoom();

        let validRanges = [];
        this.layersArray.forEach(row => {
          if (row.min <= zoom && row.max > zoom) {
            validRanges.push(row.id);
          }
        });
        validRanges.reverse();
        if (validRanges.length && !validRanges.includes(this.selectedLayer)) {
          //current selected layer isn't valid for current zoom, set to next valid one;
          this.selectedLayer = validRanges[0];
        }
      } catch (e) {
        //do nothing;
      }
    });
  },

  methods: {
    setAnalystics(thing, value) {
      if (thing == "theme") {
        let { nameShort } = this.maplayerdata.thematics[value];

        if (nameShort) {
          value = nameShort;
        }
      }

      window.mvAnalyticEvent("controls", thing, value);
    },
    setLayer(value) {
      if (this.layersKeys.includes(value)) {
        this.selectedLayer = value;
      }
    },
    setThematics(value) {
      if (this.thematicsKeys.includes(value)) {
        this.selectedTheme = value;
      }
    }
  },
  watch: {
    mobileShowFilters: function(value) {
      this.functions.updateMobileFilterView(value);
    },
    selectedLayer: function(value) {
      if (value) {
        this.functions.updateLayer(value);
      }
    },
    selectedTheme: function(value) {
      this.functions.updateThematics(value, this.colourBlindFriendly);
    }
  }
});
</script>

<style lang="scss">
.closeBoxMapControls {
  width: 44px;
  height: 75px;
  z-index: 1;
  right: 0px;
  position: absolute;
  padding-top: 2px;
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  button {
    border-radius: 0 !important;

    border-top-right-radius: 4px !important;
    margin-top: 1px !important;
    width: 44px !important;
    height: 73px !important;
    background-color: #2e3a3d !important;
    border: 1px solid white !important;
    border-top: none !important;
    border-right: none !important;
  }
}

.mapControlsAct,
.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  width: 400px !important;
}

.isTablet {
  .mapControlsAct,
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    width: 200px !important;
  }
}

.isMobile {
  &.mobileShowFilters {
    .mapboxgl-ctrl-top-left-left,
    .mapboxgl-ctrl-top-left,
    .mapboxgl-ctrl-top-right-right {
      visibility: hidden;
    }

    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
      visibility: hidden;
    }

    .mapboxgl-ctrl.mapboxgl-ctrl-group.emptyTargetMapItem.mapControlsWrapperControl {
      padding: 0;
      margin: 0;
      visibility: initial;

      margin: 10px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  &.mobileHideFilters {
    .mapboxgl-ctrl.mapboxgl-ctrl-group.emptyTargetMapItem.mapControlsWrapperControl {
      margin: 10px 10px 0 0;
      padding: 0;
    }
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
      width: 200px !important;
      margin-top: -37px;
      margin-right: 56px;
    }
  }

  .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapControlsWrapperControl {
    width: auto !important;
  }

  .mapControlsWrapperControl .mapControlsAct {
    margin: 0 !important;
    margin-top: -2px !important;
  }

  .mapControlsAct {
    width: 100% !important;
    max-width: 100vw !important;
  }

  &.mobileShowFilters .mapControlsAct {
    width: calc(100vw - 20px) !important;
    max-width: calc(100vw - 20px) !important;
  }
}

#mapControlsWrapper .mapControlsAct .mapControl_toggles.v-btn-toggle {
  background: transparent;

  button.v-btn:before {
    xdisplay: none;
  }

  .buttonLabel {
    font-size: 13px;
    font-size: 15px;
    font-size: 16px;
    font-weight: 300;
    text-transform: capitalize;
    margin-right: -5px;
  }

  .buttonLabel.layerButtonLabel {
    font-size: 15px;
    margin-right: 0;
  }

  .buttonLabel.themeButtonLabel {
    height: 75px !important;
    display: grid;
    align-items: center;

    overflow-wrap: break-word;
    text-wrap: wrap;
    width: 145px;
    white-space: normal;
    max-width: 80%;
  }

  .onePerLine .buttonLabel.themeButtonLabel {
    width: auto;
  }

  span {
    -webkit-filter: none;
    color: white;
  }

  button.v-btn.mapControl_criThemeButtons {
    border-radius: 0 !important;

    &.CRI {
      border-top-right-radius: 4px !important;
      border-top-left-radius: 4px !important;
    }

    height: 75px !important;

    border-bottom: white solid 1px !important;

    &.boarderRight {
      border-right: white solid 1px !important;
    }

    img.buttonIcon {
      height: 34px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 10px;
    }
  }

  &.mapControl_criLayerButtons button.v-btn {
    height: 30px !important;
    border-radius: 5px !important;
  }
}

.isTablet,
.isMobile {
  #mapControlsWrapper .mapControlsAct .mapControl_toggles.v-btn-toggle {
    .buttonLabel {
      font-size: 14px;
      font-weight: 300;
      &.layerButtonLabel {
        font-size: 14px;
      }
    }
  }
}

.isTablet #mapControlsWrapper .mapControlsAct .mapControl_toggles.v-btn-toggle {
  .selectedLayerButtons {
    min-width: 90%;
  }

  button.v-btn.mapControl_criThemeButtons {
    border: none !important;
    border-bottom: white solid 1px !important;
  }

  .buttonLabel.themeButtonLabel,
  button.v-btn.mapControl_criThemeButtons {
    min-height: 55px !important;
    height: auto !important;
  }
}

#mapControMobilebutton {
  button {
    color: black !important;
  }
  button:before {
    xdisplay: none !important;
  }
}

@media only screen and (max-height: 500px) {
  div.v-application
    div.v-application--wrap
    #mapControlsWrapper
    .mapControlsAct {
    .layerSection {
      margin: 1px 0 -4px 0 !important;
    }

    .mapControl_toggles.v-btn-toggle {
      &.mapControl_criLayerButtons button.v-btn {
        height: 20px !important;
        font-size: 10px;
      }

      img.buttonIcon {
        height: 22px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 5px;
      }

      .buttonLabel.themeButtonLabel,
      button.v-btn.mapControl_criThemeButtons {
        height: 32px !important;
        font-size: 10px;
      }

      .buttonLabel.layerButtonLabel,
      button.v-btn.mapControl_criLayerButtons {
        font-size: 10px;
      }
    }
  }
}
</style>
