//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { EmptyTargetControl } from "./../controls/emptyTargetControl.js";
import MapLegendUi from "./../controls/mapLegend.vue";
import { eventBus } from "../../../main";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata,
      currentThematics: state => state.filters.currentThematics
    })
  },
  watch: {
    currentThematics: function(newValue) {
      eventBus.$emit("mapLegend_updateLegendData", newValue);
    }
  },
  methods: {
    addMapLegend() {
      var emprtyTargetMenuOptions = {
        persistList: true,
        extraClass: "fakeEmptyMenuClass",
        id: "mapLegendFakeDivTarget"
      };
      const emptyTargetControlMapLegend = new EmptyTargetControl(
        emprtyTargetMenuOptions
      );
      //this.map.addControl(emptyTargetControl, 'top-right');
      this.map.addControl(emptyTargetControlMapLegend, "bottom-right");

      try {
        new MapLegendUi({
          propsData: {
            legendData: this.currentThematics,
            functions: {
              //updateThematics: this.updateThematics,
              //updateLayer: this.updateLayer
            },
            map: this.map,
            vuetifyCopy: this.$vuetify,
            //stateCopy: this.$state,
            appSettings: this.appSettings,
            maplayerdata: this.maplayerdata
          }
        }).$mount("#mapLegendFakeDivTarget");
      } catch (error) {
        //do nothink;
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
