//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { EmptyTargetControl } from "./../controls/emptyTargetControl.js";
import suburbListUi from "./../controls/suburbList.vue";
//import { eventBus } from "../../../main";
import suburbListRaw from "../../../assets/data/SuburbList.json";

let suburbList = suburbListRaw.map(row => {
  let { Name: name, CRI, G_U, G_E, G_T, G_V, latitude, longitude } = row;

  try {
    name = name.replace("(Vic.)", "").trim();
  } catch (error) {
    //do nothing;
  }

  return { name, CRI_extra: CRI, CRI, G_U, G_E, G_T, G_V, latitude, longitude };
});

function registerControlPosition(map, positionName) {
  if (map._controlPositions[positionName]) {
    return;
  }
  var positionContainer = document.createElement("div");
  positionContainer.className = `mapboxgl-ctrl-${positionName}`;
  map._controlContainer.appendChild(positionContainer);
  map._controlPositions[positionName] = positionContainer;
}

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata,
      currentThematics: state => state.filters.currentThematics
    })
  },
  watch: {},
  methods: {
    addSuburbList() {
      registerControlPosition(this.map, "top-left-left");

      let divTargetId = "mapSuburbListFakeDivTarget";

      var emprtyTargetSuburbListOptions = {
        persistList: true,
        extraClass: "fakeEmptyMenuClass mapSuburbListDiv",
        id: divTargetId
      };
      const emptyTargetSuburbListLegend = new EmptyTargetControl(
        emprtyTargetSuburbListOptions
      );
      //this.map.addControl(emptyTargetControl, 'top-right');
      this.map.addControl(emptyTargetSuburbListLegend, "top-left-left");

      try {
        new suburbListUi({
          propsData: {
            suburbList: suburbList,
            legendData: this.currentThematics,
            functions: {
              flyToPoint: this.flyToPoint,
              updateMobileSuburbView: this.updateMobileSuburbView
            },
            map: this.map,
            vuetifyCopy: this.$vuetify,
            browserDetectCopy: this.$browserDetect,
            //stateCopy: this.$state,
            appSettings: this.appSettings,
            maplayerdata: this.maplayerdata
          }
        }).$mount(`#${divTargetId}`);
      } catch (error) {
        //do nothink;
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
