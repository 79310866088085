//import _, { initial } from "lodash";
import { mapState } from "vuex";

import PopupContent from "./../popup/PopupContent.vue";
import AnimatedPopup from "mapbox-gl-animated-popup";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata
    }),
    hasInfoIdsArray() {
      return Object.keys(this.maplayerdata.layers);
    }
  },

  methods: {
    clearAllPopups(where) {
      if (where) {
        //do nothing;
      }
      try {
        if (this.currentPopup) {
          this.currentPopup.remove();
        }
      } catch (error) {
        //do nothing;
      }
      this.currentPopup = null;

      this.$store.dispatch("navUpdate_infoWindowTarget", {});
    },

    initializeInfoWindowEvents() {
      let layers = this.map.getStyle().layers;
      for (const layer of layers) {
        let { id: layerId } = layer;

        if (
          layerId &&
          this.hasInfoIdsArray &&
          this.hasInfoIdsArray.includes(layerId)
        ) {
          this.map.on("mouseenter", layerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          this.map.on("mouseleave", layerId, () => {
            this.map.getCanvas().style.cursor = "";
          });

          this.map.on("click", layerId, e => {
            this.closeMapControlsFilters();

            this.clearAllPopups("A");

            // Copy coordinates array.
            //const coordinates = e.features[0].geometry.coordinates.slice();
            const coordinates = e.lngLat;
            let [feature, otherFeatures] = e.features;
            if (otherFeatures) {
              //do notningk!!!
            }

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            /*
            this.currentPopup = new window.mapboxgl.Popup({
            this.currentPopup = new window.mapboxgl.Popup({
              className: "galPopup mvPopupAll",
              closeButton: false
            })
*/

            this.currentPopup = new AnimatedPopup({
              className: "galPopup mvPopupAll",
              closeButton: false,

              openingAnimation: {
                duration: 300,
                easing: "easeInOutExpo", //"easeOutExpo",//"easeOutElastic",
                transform: "scale"
              },
              closingAnimation: {
                duration: 300,
                easing: "easeInBack",
                transform: "scale"
              }
            })
              .setLngLat(coordinates)
              .setHTML('<div id="vue-popup-content"></div>')
              //.setOffset(this.getOffest())
              .addTo(this.map);

            try {
              let coordinatesObj = coordinates;
              if (
                coordinatesObj[0] &&
                coordinatesObj[1] &&
                !isNaN(coordinatesObj[1])
              ) {
                coordinatesObj = {
                  lng: coordinatesObj[0],
                  lat: coordinatesObj[1]
                };
              }

              this.$store.dispatch(
                "navUpdate_infoWindowTarget",
                coordinatesObj
              );

              new PopupContent({
                propsData: {
                  properties: feature.properties,
                  clearAllPopups: this.clearAllPopups,
                  vuetifyCopy: this.$vuetify,
                  browserDetectCopy: this.$browserDetect,
                  appSettings: this.appSettings,
                  layerId: layerId,
                  maplayerdata: this.maplayerdata
                }
              }).$mount("#vue-popup-content");
            } catch (error) {
              //do nothink;
            }
          });
        }
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
