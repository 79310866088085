<template>
  <div v-show="!$vuetify.breakpoint.mdAndUp" class="vLogosWrapper">
    <div>
      <a @click="contactFunction" target="_blank">
        <img src="/assets/logos/TractOneMap.png" style="max-height: 35px;" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "verticalLogos",

  props: { contactFunction: Function }
};
</script>
<style>
.vLogosWrapper img {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.vLogosWrapper {
  height: 0;
  width: 0;
  overflow: visible;
  position: absolute;
}
.vLogosWrapper > div {
  bottom: 34px;
  position: fixed;
  left: 0px;
}
</style>
