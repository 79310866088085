var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',{staticClass:"suburbList dataTableView",class:{
    mobileViewSubrubList:
      _vm.$vuetify.breakpoint.mdAndDown && _vm.mobileShowSuburbList,
    suburbList: true
  },attrs:{"color":'success'}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
      (!_vm.$vuetify.breakpoint.mdAndDown && !_vm.desktopShowSuburbList) ||
        (_vm.$vuetify.breakpoint.mdAndDown && !_vm.mobileShowSuburbList)
    ),expression:"\n      (!$vuetify.breakpoint.mdAndDown && !desktopShowSuburbList) ||\n        ($vuetify.breakpoint.mdAndDown && !mobileShowSuburbList)\n    "}],staticClass:"mapElementButton",attrs:{"tile":"","color":"secondary"},on:{"click":function($event){_vm.$vuetify.breakpoint.mdAndDown
        ? (_vm.mobileShowSuburbList = !_vm.mobileShowSuburbList)
        : (_vm.desktopShowSuburbList = !_vm.desktopShowSuburbList);
      _vm.setAnalystics('suburbList', 'show');}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-format-list-numbered ")]),(false)?_c('font',[_vm._v("Suburb List")]):_vm._e()],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
      (!_vm.$vuetify.breakpoint.mdAndDown && _vm.desktopShowSuburbList) ||
        (_vm.$vuetify.breakpoint.mdAndDown && _vm.mobileShowSuburbList)
    ),expression:"\n      (!$vuetify.breakpoint.mdAndDown && desktopShowSuburbList) ||\n        ($vuetify.breakpoint.mdAndDown && mobileShowSuburbList)\n    "}],attrs:{"height":"100%","color":'secondary',"width":_vm.$vuetify.breakpoint.mdAndDown ? '100%' : _vm.tableWidth}},[(false)?_c('v-card-title'):_vm._e(),_c('v-form',{staticClass:"pl-3 pb-2 pt-0",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-row"},[(false)?_c('div',{staticClass:"white--text"},[_vm._v(" isIOS: "+_vm._s(_vm.isIOS)+" itemsPerPageAuto "+_vm._s(_vm.itemsPerPageAuto)+", windowHeight:"+_vm._s(_vm.windowHeight)+" avaliableHight:"+_vm._s(_vm.avaliableHight)+" windowHeightRaw:"+_vm._s(_vm.windowHeightRaw)+" ")]):_vm._e(),_c('v-text-field',{staticClass:"pr-1 ma-0  pt-1",attrs:{"dark":"","color":"primary","append-icon":"mdi-filter","xappend-icon":"fa-solid fa-filter","label":"Suburb Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown && _vm.mobileShowSuburbList),expression:"$vuetify.breakpoint.mdAndDown && mobileShowSuburbList"}],staticClass:"ma-1 closeButton",attrs:{"large":"","icon":"","color":"white","title":"Close Suburb List"},on:{"click":function($event){_vm.mobileShowSuburbList = false;
            _vm.setAnalystics('suburbList', 'hide');}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndDown && _vm.desktopShowSuburbList),expression:"!$vuetify.breakpoint.mdAndDown && desktopShowSuburbList"}],staticClass:"ma-0 ml-3 closeButton",attrs:{"icon":"","color":"white","title":"Close Suburb List"},on:{"click":function($event){_vm.desktopShowSuburbList = false;
            _vm.setAnalystics('suburbList', 'hide');}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-data-table',{staticClass:"pt-2 dataTableWrapper",staticStyle:{"border-radius":"0!important"},attrs:{"width":"100%","height":"100%","headers":_vm.headers,"items":_vm.suburbList,"search":_vm.search,"mobile-breakpoint":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [_vm.itemsPerPage ].concat( _vm.itemPerPageOption)
      },"dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.latitude",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"pa-0 ma-0 ml-2"},[_c('v-btn',{staticClass:"ma-0 pa-0 flyToButton",staticStyle:{"position":"relative","top":"-3px"},attrs:{"color":"transparent","x-small":"","rounded":"","depressed":""},on:{"click":function($event){_vm.functions.flyToPoint(item);
              _vm.setAnalystics('itemClickedOn');}}},[_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"color":"accent","right":true}},[_vm._v("mdi-map-marker-outline")])],1)],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('a',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"},on:{"click":function($event){_vm.functions.flyToPoint(item);
            _vm.setAnalystics('itemClickedOn');}}},[_c('div',{staticClass:"pa-0 pt-1",staticStyle:{"white-space":"nowrap","width":"120px"}},[_c('span',{staticStyle:{"display":"inline-block","overflow":"hidden","text-overflow":"ellipsis","width":"100%"},attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])])]}},{key:"item.CRI",fn:function(ref){
            var item = ref.item;
return [_c('suburbListBar',{attrs:{"item":item,"fieldId":_vm.currentThematicsId,"fieldName":_vm.currentThematicsName,"functions":_vm.functions,"currentThematics":_vm.currentThematics,"maplayerdata":_vm.maplayerdata}})]}},{key:"item.G_U",fn:function(ref){
            var item = ref.item;
return [_c('suburbListBar',{attrs:{"item":item,"fieldId":_vm.currentThematicsId,"fieldName":_vm.currentThematicsName,"functions":_vm.functions,"currentThematics":_vm.currentThematics,"maplayerdata":_vm.maplayerdata}})]}},{key:"item.G_E",fn:function(ref){
            var item = ref.item;
return [_c('suburbListBar',{attrs:{"item":item,"fieldId":_vm.currentThematicsId,"fieldName":_vm.currentThematicsName,"functions":_vm.functions,"currentThematics":_vm.currentThematics,"maplayerdata":_vm.maplayerdata}})]}},{key:"item.G_T",fn:function(ref){
            var item = ref.item;
return [_c('suburbListBar',{attrs:{"item":item,"fieldId":_vm.currentThematicsId,"fieldName":_vm.currentThematicsName,"functions":_vm.functions,"currentThematics":_vm.currentThematics,"maplayerdata":_vm.maplayerdata}})]}},{key:"item.G_V",fn:function(ref){
            var item = ref.item;
return [_c('suburbListBar',{attrs:{"item":item,"fieldId":_vm.currentThematicsId,"fieldName":_vm.currentThematicsName,"functions":_vm.functions,"currentThematics":_vm.currentThematics,"maplayerdata":_vm.maplayerdata}})]}}],null,false,3242584535)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }