//import _, { initial } from "lodash";

import { mapState } from "vuex";

function registerControlPosition(map, positionName) {
  if (map._controlPositions[positionName]) {
    return;
  }
  var positionContainer = document.createElement("div");
  positionContainer.className = `mapboxgl-ctrl-${positionName}`;
  map._controlContainer.appendChild(positionContainer);
  map._controlPositions[positionName] = positionContainer;
}

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata
    })
  },

  methods: {
    addMapZoomFullScreen() {
      registerControlPosition(this.map, "top-right-right");

      this.map.addControl(
        new window.mapboxgl.NavigationControl(),
        "top-right-right"
      );

      // Add fullscreen control to the top-right corner
      this.map.addControl(
        new window.mapboxgl.FullscreenControl(),
        "top-right-right"
      );
      /*
      this.map.addControl(
        new window.mapboxgl.NavigationControl(),
        "top-right"
      );

      // Add fullscreen control to the top-right corner
      this.map.addControl(
        new window.mapboxgl.FullscreenControl(),
        "top-right"
      );
*/
    },
    addAddressSearch() {
      var theIntervId = setInterval(
        () => {
          //https://docs.mapbox.com/api/search/search-box/
          //https://docs.mapbox.com/mapbox-search-js/api/web/search/#mapboxsearchbox#options
          if (window.MapboxSearchBox && window.MapboxSearchBox != "notReady") {
            const searchBox = new window.MapboxSearchBox();
            searchBox.accessToken = this.appSettings.map.accessToken;

            let bbox = this.appSettings.map.bounds;

            searchBox.placeholder = "Address Search";
            searchBox.options = {
              country: "AU",
              types: "address, poi, district, place, locality, neighborhood",
              proximity: [144.946457, -37.840935],
              bbox
            };
            //searchBox.marker = false;

            searchBox.mapboxgl = window.mapboxgl;
            //this.map.addControl(searchBox, "top-right-right");
            this.map.addControl(searchBox, "top-right");

            clearInterval(theIntervId);

            searchBox.addEventListener("retrieve", () => {
              window.mvAnalyticEvent("addressSearchResult_Clicked");
            });
          }
        },

        10
      );
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
