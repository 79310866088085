<template>
  <div class="text-center">
    <v-dialog
      v-model="openShare"
      :width="700"
      transition="dialog-top-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="linkButton" v-show="false">
          Share
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-toolbar dark color="rgb(20, 96, 150)" elevation="1">
          <v-toolbar color="#acd265" elevation="1" v-if="false"></v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>Share the Climate Resilience Index</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="openShare = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-checkbox
            v-model="includeSearchQueryString"
            :label="`Share current setup (theme, location, dataset, zoom....)`"
          ></v-checkbox>
          <input
            type="text"
            :value="pageUrl"
            id="myInput"
            readonly
            style="width: 100%;border:1px solid grey"
          />
        </v-card-text>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="pageUrl"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
            >
              <v-btn class="shareButton ma-1" outlined :color="linkBorderColor">
                <v-icon>{{ network.icon }}</v-icon>
                {{ network.name }}
              </v-btn>
            </ShareNetwork>

            <v-btn
              @click="copyLink()"
              class="shareButton ma-1"
              outlined
              :color="linkBorderColor"
            >
              <v-icon>mdi-content-copy</v-icon>
              Copy Link
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "./../../main";

export default {
  name: "ShareDialog",
  components: {},
  data() {
    return {
      openShare: false,
      path: "https://climateresilienceindex.onemap.com.au/",
      searchString: window.location.search,

      linkBorderColor: "secondary",
      includeSearchQueryString: true,
      sharing: {
        title:
          "Climate Resilience Index. Melbourne's Capacity to Address Climate Change.",
        description:
          "Tool to assess the capacity of Melbourne's neighbourhoods to effectively address climate change, through evaluating the determinants of urban environments that impact sustainable living.",
        quote:
          "Explore how well Melbourne's suburbs are prepared for climate change.",
        hashtags: "ClimateResilience,CRI,ClimateChange,Melbourne"
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "mdi-facebook",
          color: "#1877f2"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "mdi-linkedin",
          color: "#007bb5"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "mdi-twitter",
          color: "#1da1f2"
        },
        { network: "email", name: "Email", icon: "mdi-email", color: "#333333" }

        /*

//{ network: "messenger", name: "Messenger", icon: "mdi-facebook", color: "#0084ff" },

        { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
        { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
        { network: 'fakeblock', name: 'Custom Network', icon: 'fab fah fa-lg fa-vuejs', color: '#41b883' }*/
      ]
    };
  },
  computed: {
    ...mapState({}),
    pageUrl() {
      return `${this.path}${
        this.includeSearchQueryString ? this.searchString : ""
      }`;
    }
  },
  methods: {
    copyLink() {
      var copyText = document.getElementById("myInput");
      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      // Alert the copied text
    }
  },
  created() {
    eventBus.$on("openShareDialog", () => {
      window.mvAnalyticEvent("dialogOpen_Share");

      this.openShare = true;
    });
  },
  mounted() {
    setTimeout(() => {
      //this.openShare = true;
    }, 10);
  },

  watch: {
    openShare: function() {
      this.searchString = window.location.search;
    }
  }
};
</script>

<style>
.shareButton {
  text-transform: none !important;
}
</style>
