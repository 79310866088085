<template>
  <div class="textBodyHeightWrapper" :id="`aboutContentDiv_${id}`">
    <div class="textBodyHeight">
      <v-card flat>
        <v-card-text class="sources">
          <div class="WordSection1">
            <p class="MsoNormal">
              <span lang="EN-US"
                >Australian Bureau of Statistics [ABS]. (2021). Destination
                Zones [Data set].
                <a
                  target="_blank"
                  href="https://www.abs.gov.au/statistics/standards/australian-statistical-geography-standard-asgs-edition-3/jul2021-jun2026/non-abs-structures/destination-zones#cite-window2."
                  >https://www.abs.gov.au/statistics/standards/australian-statistical-geography-standard-asgs-edition-3/jul2021-jun2026/non-abs-structures/destination-zones#cite-window2.</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >Department of Energy, Environment and Climate Action [DEECA].
                (2023). <span class="SpellE">VicMap</span> Features of Interest
                [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-features-of-interest"
                  >https://discover.data.vic.gov.au/dataset/vicmap-features-of-interest</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). Public Land Management (PLM25) [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/public-land-management-plm25"
                  >https://discover.data.vic.gov.au/dataset/public-land-management-plm25</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). VEAC Metropolitan Melbourne Open Space Inventory
                [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/veac-metropolitan-melbourne-open-space-inventory."
                  >https://discover.data.vic.gov.au/dataset/veac-metropolitan-melbourne-open-space-inventory</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). <span class="SpellE">VicMap</span> Hydro - Water
                Polygon [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-hydro-water-polygon"
                  >https://discover.data.vic.gov.au/dataset/vicmap-hydro-water-polygon</a
                >
                <o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2021). <span class="SpellE">VicMap</span> Vegetation
                Tree Extent [Data set].
                <a
                  target="_blank"
                  href="https://delwp.maps.arcgis.com/home/item.html?id=8da9b2bc20e1471fb0c3afbde856e440#overview"
                  >https://delwp.maps.arcgis.com/home/item.html?id=8da9b2bc20e1471fb0c3afbde856e440#overview</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). Victorian Coastal Inundation Sea Level Rise
                Storm Tide 2100 [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/victorian-coastal-inundation-sea-level-rise-storm-tide-2100"
                  >https://discover.data.vic.gov.au/dataset/victorian-coastal-inundation-sea-level-rise-storm-tide-2100</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). Victorian Coastal Inundation Sea Level Rise 2100
                [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/victorian-coastal-inundation-sea-level-rise-2100."
                  >https://discover.data.vic.gov.au/dataset/victorian-coastal-inundation-sea-level-rise-2100</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2024). <span class="SpellE">Vicmap</span> Planning -
                Planning Scheme Overlay Polygon [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-planning-planning-scheme-overlay-polygon1"
                  >https://discover.data.vic.gov.au/dataset/vicmap-planning-planning-scheme-overlay-polygon1</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2022). <span class="SpellE">Vicmap</span> Planning -
                Planning Scheme Zone Polygon [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-planning-planning-scheme-zone-polygon"
                  >https://discover.data.vic.gov.au/dataset/vicmap-planning-planning-scheme-zone-polygon</a
                >
                <o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). Designated Bushfire Prone Area (BPA) [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/designated-bushfire-prone-area-bpa"
                  >https://discover.data.vic.gov.au/dataset/designated-bushfire-prone-area-bpa</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). <span class="SpellE">Vicmap</span> Property -
                Parcel Polygon with Parcel Detail [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-property-parcel-polygon-with-parcel-detail."
                  >https://discover.data.vic.gov.au/dataset/vicmap-property-parcel-polygon-with-parcel-detail</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2023). <span class="SpellE">Vicmap</span> Admin - Local
                Government Area (LGA) Polygon Aligned to Property [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-admin-local-government-area-lga-polygon-aligned-to-property"
                  >https://discover.data.vic.gov.au/dataset/vicmap-admin-local-government-area-lga-polygon-aligned-to-property</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DEECA. (2018). Metropolitan Melbourne Urban Heat Islands and
                Urban Vegetation 2018 [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/metropolitan-melbourne-urban-heat-islands-and-urban-vegetation-2018."
                  >https://discover.data.vic.gov.au/dataset/metropolitan-melbourne-urban-heat-islands-and-urban-vegetation-2018.</a
                >&nbsp;<o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >Department of Transport and Planning [DTP]. (2022).
                <span class="SpellE">VicMap</span> Transport - Road Line [Data
                set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/vicmap-transport-road-line"
                  >https://discover.data.vic.gov.au/dataset/vicmap-transport-road-line</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >DTP. (2023). Public Transport a collection of PTV datasets
                [Data set].
                <a
                  target="_blank"
                  href="https://discover.data.vic.gov.au/dataset/public-transport-a-collection-of-ptv-datasets"
                  >https://discover.data.vic.gov.au/dataset/public-transport-a-collection-of-ptv-datasets</a
                >
                <o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"
                >Overture Maps Foundation - Places. (2023). Overture [Data set].
                <a target="_blank" href="https://overturemaps.org/"
                  >https://overturemaps.org/</a
                ><o:p></o:p
              ></span>
            </p>

            <p class="MsoNormal">
              <span lang="EN-US"><o:p>&nbsp;</o:p></span>
            </p>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutSources",

  props: {
    id: String
  }
};
</script>

<style scoped>
.MsoNormal {
  font-size: 16px !important;
}
</style>
