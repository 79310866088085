<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="720" class="video">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on"> </span>
      </template>

      <v-card class="youTubeDialog" v-if="dialog">
        <div ref="box" style="">
          <youtube
            player-width="100%"
            :video-id="getUrl('https://www.youtube.com/watch?v=rsqtUJQ3Fzk')"
            :player-vars="{ autoplay: 1 }"
          ></youtube>
        </div>

        <v-card-actions class="my-0 py-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getIdFromURL } from "vue-youtube-embed";

import { eventBus } from "./../../main";

export default {
  data() {
    return {
      dialog: false,
      maxHeight: 600
    };
  },
  computed: {},
  methods: {
    getUrl(url) {
      let videoId = getIdFromURL(url);
      return videoId;
    }
  },
  created() {
    eventBus.$on("loadVideo", () => {
      this.dialog = true;
    });
  }
};
</script>
<style>
.youTubeDialog {
  max-height: 100vh;
  max-width: 100vw;
}

.youTubeDialog,
.youTubeDialog iframe {
  min-height: 500px;
}

@media only screen and (max-height: 500px) {
  .youTubeDialog,
  .youTubeDialog iframe {
    min-height: initial;
  }

  .youTubeDialog iframe,
  .streetViewDialog iframe {
    max-height: calc(100vh - 150px);
  }
}
</style>
