<template>
  <div
    style=" height:20px; cursor: pointer"
    class="d-flex pt-2x barWrapper"
    :class="{
      'd-flex': true,
      barWrapper: true,
      cri: fieldId === 'CRI'
    }"
    @click="
      functions.flyToPoint(item);
      setAnalystics('itemClickedOn');
    "
  >
    <v-progress-linear
      v-model="value"
      :color="color"
      height="20"
      :style="{
        opacity: maplayerdata.listOpacity,

        'pointer-events': 'none'
      }"
    >
      <template v-slot:default="{ value }">
        <font :color="dark ? 'white' : 'black'"> {{ value }} </font>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

export default {
  name: "SuburbListBar",
  components: {},
  methods: {
    setAnalystics(thing, value) {
      window.mvAnalyticEvent("suburbList", thing, value);
    }
  },

  props: {
    item: Object,
    fieldId: String,
    fieldName: String,
    functions: Object,
    currentThematics: Object,
    maplayerdata: Object
  },

  computed: {
    value() {
      let value = this.item[this.fieldId];

      try {
        //value = parseFloat(value);
      } catch (error) {
        //do nothing;
      }
      return value;
    },
    color() {
      let color = "grey";
      let value = this.value;
      let legendArray = this?.currentThematics?.legendArray;
      let xcolor;
      try {
        color = legendArray.filter(row => {
          return value >= row.min && value <= row.max;
        })[0].color;
      } catch (error) {
        //do nothing;
      }
      if (xcolor) {
        //do nothing;
      }

      return color;
    },
    dark() {
      let isDark = false;

      if (
        this.fieldId === "CRI" ||
        this.fieldId === "G_U" ||
        this.fieldId === "G_E" ||
        this.fieldId === "G_T"
      ) {
        isDark = this.value > 69.99 ? true : false;
      } else if (this.fieldId === "G_V") {
        isDark = this.value > 84 ? true : false;
      } else if (this.fieldId === "G_T") {
        isDark = this.value > 89.99 ? true : false;
      } else {
        try {
          var c = this.color.substring(1); // strip #
          var rgb = parseInt(c, 16); // convert rrggbb to decimal
          var r = (rgb >> 16) & 0xff; // extract red
          var g = (rgb >> 8) & 0xff; // extract green
          var b = (rgb >> 0) & 0xff; // extract blue

          var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

          if (luma < 50) {
            isDark = true;
          }
          if (isDark) {
            //do nothing;
          }
        } catch (error) {
          //do nothing;
        }
      }
      return isDark;
    }
  }
};
</script>

<style>
.v-btn.oneLineEclipsText span.buttitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn.multiLine span.buttitle {
  white-space: normal;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-btn.multiLine {
  height: auto !important;
  min-height: 36px;
}

.v-btn.multiLine span.v-btn__content {
  margin: 8px 7px !important;
}

.v-btn.multiLine span.v-btn__content i {
  margin-left: 6px;
  margin-right: 4px;
}
.barWrapper {
  width: 120px;
}

.barWrapper.cri {
  width: 180px;
}

@media only screen and (max-width: 1263px) and (min-width: 400px) {
  .barWrapper {
    width: calc(90vw - 150px) !important;
  }
}
</style>
