var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex pt-2x barWrapper",class:{
    'd-flex': true,
    barWrapper: true,
    cri: _vm.fieldId === 'CRI'
  },staticStyle:{"height":"20px","cursor":"pointer"},on:{"click":function($event){_vm.functions.flyToPoint(_vm.item);
    _vm.setAnalystics('itemClickedOn');}}},[_c('v-progress-linear',{style:({
      opacity: _vm.maplayerdata.listOpacity,

      'pointer-events': 'none'
    }),attrs:{"color":_vm.color,"height":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var value = ref.value;
return [_c('font',{attrs:{"color":_vm.dark ? 'white' : 'black'}},[_vm._v(" "+_vm._s(value)+" ")])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }