//import _, { initial } from "lodash";
import { mapState } from "vuex";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      maplayerdata: state => state.global.maplayerdata
    })
  },

  methods: {
    addExtraLayers() {
      let username = "climateresilienceindex";
      let style_id = "clutbpi3q002401rdc90264wo";

      fetch(
        `https://api.mapbox.com/styles/v1/${username}/${style_id}?access_token=${this.appSettings.map.accessToken}`
      )
        .then(response => response.json())
        .then(styleJson => {
          // Extract the layer you want to add
          const layerToAdd1 = styleJson.layers.find(
            layer => layer.id === "cri-suburb-1u6rni"
          );
          // Add the layer to your Mapbox GL JS map
          this.map.addLayer(layerToAdd1);

          const layerToAdd2 = styleJson.layers.find(
            layer => layer.id === "cri-suburb-1u6rni (1)"
          );

          this.map.addLayer(layerToAdd2);
        })
        .catch(error => {
          console.error("Error fetching Mapbox Studio style:", error);
        });
    }
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {}
};
