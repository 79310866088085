<template>
  <div class="pa-1" style="xbackground-color:white;">
    <div
      class="scoreHeading"
      v-if="decodedValues.ranks.RANK_IN_SUBURB && properties.Parent"
    >
      <div style="width:100%"><strong>Rank: </strong></div>
      <div>
        {{ decodedValues.ranks.RANK_IN_SUBURB }} (top
        {{ decodedValues.ranks.RANK_IN_SUBURB_percentage }}%) within suburb of
        {{ properties.Parent }}
        {{ decodedValues.ranks.OVERALL_RANK }} (top
        {{ decodedValues.ranks.OVERALL_RANK_percentage }}%) Overall
      </div>
    </div>

    <div
      class="scoreHeading"
      v-if="!decodedValues.ranks.RANK_IN_SUBURB || !properties.Parent"
    >
      <strong>Rank: </strong>

      {{ decodedValues.ranks.OVERALL_RANK }}
      (top
      {{ decodedValues.ranks.OVERALL_RANK_percentage }}%) out of all suburbs.
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupDetials",
  components: {},

  data: () => ({
    layouts: [0, 1]
  }),

  props: {
    properties: Object,
    maplayerdata: Object,
    decodedValues: Object
  },

  computed: {}
};
</script>
