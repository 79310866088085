<template>
  <div id="aboutcontent" class="aboutText">
    <Background :id="id" v-if="id == 'Background'" />
    <FAQ :id="id" v-if="id == 'FAQ'" />
    <Sources :id="id" v-if="id == 'Sources'" />
    <Team :id="id" v-if="id == 'Team'" />
  </div>
</template>

<script>
import Background from "./Background.vue";
import FAQ from "./FAQ.vue";
import Sources from "./Sources.vue";
import Team from "./Team.vue";

export default {
  name: "AboutContent",
  components: { Background, FAQ, Sources, Team },

  props: {
    id: String
  }
};
</script>
<style lang="scss">
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}

.MsoNormal a {
  color: blue !important;
  text-decoration: underline;
  display: inline-block;
}

#aboutcontent {
  ol,
  ul {
    list-style: initial;
  }
}
</style>
