var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown && !_vm.mobileShowLegend),expression:"$vuetify.breakpoint.mdAndDown && !mobileShowLegend"}],staticClass:"mapElementButton",attrs:{"tile":"","color":"secondary"},on:{"click":function($event){_vm.mobileShowLegend = !_vm.mobileShowLegend;
      _vm.setAnalystics('mobileLegend', 'show');}}},[(false)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-map-legend ")]):_vm._e(),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-format-list-bulleted-square ")]),(false)?_c('font',[_vm._v("Suburb List")]):_vm._e()],1),(
      _vm.currentThematics &&
        _vm.currentThematics.fieldDetails &&
        _vm.currentThematics.fieldDetails.name
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      (_vm.$vuetify.breakpoint.mdAndDown && _vm.mobileShowLegend) ||
        !_vm.$vuetify.breakpoint.mdAndDown
    ),expression:"\n      ($vuetify.breakpoint.mdAndDown && mobileShowLegend) ||\n        !$vuetify.breakpoint.mdAndDown\n    "}],staticClass:"legendBlox pa-2",staticStyle:{"min-width":"110px"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"legendHeading font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentThematics.fieldDetails.name)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown && _vm.mobileShowLegend),expression:"$vuetify.breakpoint.mdAndDown && mobileShowLegend"}],staticClass:"ma-1 mt-0",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.mobileShowLegend = false;
          _vm.setAnalystics('mobileLegend', 'hide');}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._l((_vm.currentThematics.legendArray.slice().reverse()),function(item,i){return _c('div',{key:("legendItem_" + i)},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"legendItemColorBox mr-2",style:({
            opacity: _vm.maplayerdata.legendOpacity,
            overflow: 'hidden',
            height: '20px',
            width: '20px',
            'background-color': item.color
          })}),_c('div',{staticClass:"legendItem"},[_vm._v(_vm._s(item.min)+" - "+_vm._s(item.max))])])])}),_c('div',{staticClass:"mt-2"},_vm._l((_vm.extraLegendItems),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.showIn[_vm.currentLayerId]),expression:"item.showIn[currentLayerId]"}],key:("legendExtraItem_" + i),staticClass:"mt-1",staticStyle:{"min-height":"22px"}},[(item.showIn[_vm.currentLayerId])?_c('div',{staticClass:"d-flex align-start"},[(item.src)?_c('img',{staticClass:" mr-2",attrs:{"height":"20px","width":"20px","src":item.src}}):_vm._e(),_c('div',{staticClass:"legendItem"},[_vm._v(_vm._s(item.name))])]):_vm._e()])}),0),_c('v-checkbox',{staticClass:"colourBlindFriendlyButton ma-0 pa-0 mt-2",attrs:{"dense":"","color":"rgb(20, 96, 150)","label":"Colour Blind Friendly Theme"},model:{value:(_vm.colourBlindFriendly),callback:function ($$v) {_vm.colourBlindFriendly=$$v},expression:"colourBlindFriendly"}})],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }