<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :width="computerWidth">
      <template v-slot:activator="{ on, attrs }" v-if="false">
        <v-btn color="secondary" v-bind="attrs" v-on="on" v-show="false">
          Open Dialog
        </v-btn>
      </template>
      <v-card class="AboutDialogWrapper">
        <v-toolbar dark color="secondary" elevation="1">
          <v-toolbar color="#acd265" elevation="1" v-if="false"></v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>Contact Us</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="contactTextBody">
          <v-row align="left" no-gutters class="pt-4">
            <v-col cols="12" md="6" class="mt-1  px-4">
              <a href="https://tract.com.au" target="_blank">
                <div style="height:50px">
                  <img
                    class="mt-1 "
                    style="max-height: 40px;"
                    src="/assets/logos/Tract.png"
                  />
                </div>
                <font v-show="false">https://tract.com.au</font>
              </a>
            </v-col>

            <v-col cols="12" md="6" class="mt-1 px-4">
              <a href="https://www.onemap.com.au/" target="_blank">
                <div style="height:43px">
                  <img
                    class="onemap "
                    style="max-height: 43px;"
                    src="/assets/logos/onemap-logo-hiLo.png"
                  />
                </div>

                <font v-show="false">https://www.onemap.com.au/</font>
              </a>
            </v-col>
          </v-row>

          <v-card-title>Tract Consultants</v-card-title>

          <v-card-text class="aboutText">
            <ul class="contact">
              <li>
                Tract is a national planning and design practice specialising in
                town planning, urban design, landscape architecture and
                associated digital media.
              </li>
              <li>
                As planners and designers our charter is to deliver sustainable
                and memorable solutions, shaping places for living, leisure and
                work, and the infrastructure that supports and connects these
                places.
              </li>
            </ul>

            <a href="https://tract.com.au" target="_blank">
              https://tract.com.au
            </a>
          </v-card-text>

          <v-card-title>OneMap</v-card-title>
          <v-card-text class="aboutText">
            <ul class="contact">
              <li>
                OneMap is a Property Intelligence and Site Finding Solutions
                Provider.
              </li>
              <li>
                OneMap's team of GIS Experts and Software Developers is
                dedicated to reshaping the landscape of understanding in
                property intelligence.
              </li>
              <li>
                Through the lens of cutting-edge technology and spatial
                intelligence, we illuminate the intricate tapestry of each
                location, saving our clients time, and empowering
                decision-makers to grasp the full spectrum of possibilities.
              </li>
              <li>
                OneMap provides clarity amidst complexity, guiding stakeholders
                towards informed, impactful decisions.
              </li>
            </ul>

            <a href="https://www.onemap.com.au/" target="_blank">
              https://www.onemap.com.au/
            </a>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { eventBus } from "./../../main";

export default {
  name: "TeamModal",

  components: {},

  data() {
    return {
      computerWidth: "500px",
      dialog: false
    };
  },

  created() {
    eventBus.$on("loadProjectTeamDialog", () => {
      this.dialog = true;

      window.mvAnalyticEvent("dialogOpen_team");
    });
  }
};
</script>

<style scoped>
a {
  text-decoration: underline !important;
}
.contactTextBody {
  height: auto;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
}

ul.contact {
  padding-left: 0;
}

ul.contact li {
  padding-bottom: 10px;
}
</style>
